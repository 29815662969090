/* --------------------------------
   instafeed
-------------------------------- */

.instafeed
{
	.section:last-child & { margin-bottom: 30px; }

	@include media-breakpoint-up(sm)
	{
		margin-right: 30px;
	}

	.__item
	{
		position: relative;
		vertical-align: top;
		width: 100%;

		&:hover
		{
			.__image > a { background-color: rgba($primary-color,0.5); }
		}
	}

	.__image
	{
		position: relative;
		width: 100%;
		height: 0;
		min-height: 150px;
		margin: auto;
		padding-top: percentage(257/326);
		overflow: hidden;

		a,
		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include object-fit(cover, 50% 50%);
		}

		a
		{
			padding: 15px;
			line-height: 1;
			font-size: 2rem;
			color: $white;
			@include transition(
				background-color 0.3s ease-in-out,
				color            0.3s ease-in-out
			);

			@include media-breakpoint-up(sm)
			{
				padding: 30px;
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}