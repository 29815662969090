/* --------------------------------
   team
-------------------------------- */

.team
{
	$itemMarginBottom: 50px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $itemMarginBottom;
		text-align: center;

		@include media-breakpoint-up(sm)
		{
			padding: 0 20px;
		}
	}

	.__image
	{
		position: relative;
		width: 100%;
		min-height: 200px;
		margin: auto;
		overflow: hidden;

		&:before
		{
			content: "";
			display: block;
			padding-top:  percentage(280/225);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include object-fit(cover, 50% 50%);
		}
	}

	.__content
	{
		padding-top: 37px;
		line-height: 1.2;
	}

	.__position
	{
		font-size: 1.4rem;
		font-weight: 700;
		text-transform: uppercase;
		color: $primary-color;
	}

	.__name { margin-top: 5px; }
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}