#app
{
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	>main    { flex: 1 0 auto; }
	>footer  { flex: 0 0 auto; }
}

iframe
{
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

table
{
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

img
{
	vertical-align: middle;
	-webkit-user-drag: none;
	user-drag: none;
	@include userSelect(none);

	&.lazy { max-width: 100%; }
	&.loaded { height: auto; }
}

.lazy[src]
{
	opacity: 0;
	will-change: opacity;
	@include transition(opacity 200ms);

	&.loaded { opacity: 1; }
}

.section
{
	position: relative;
	padding-top: 70px;
	padding-bottom: 70px;
	z-index: 0;

	&--no-pt { padding-top: 0 !important; }
	&--no-pb { padding-bottom: 0 !important; }

	&--gray-bg { background-color: #fbfbfb; }
	&--bg-gray-2 { background-color: #f7f7f7; }

	&--bg-img { @extend %bg-cover; }

	.spacer
	{
		flex: 0 0 100%;
		width: 100%;
		min-height: 1px;
	}

	.shape
	{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		overflow: hidden;
		z-index: -5;

		svg
		{
			vertical-align: middle;
			width: 100%;
			min-width: 1000px;
			height: auto;
		}
	}

	.decor-el
	{
		position: absolute;
		display: none;
		z-index: -1;

		&--1
		{
			top: 20%;
			right: 0;
		}

		&--2
		{
			top: 5%;
			left: 0;
		}
	}

	@include media-breakpoint-up(sm)
	{
		
	}

	@include media-breakpoint-up(md)
	{
		padding-top: 100px;
		padding-bottom: 100px;
	}

	@include media-breakpoint-up(lg)
	{
		padding-top: 140px;
		padding-bottom: 140px;

		.decor-el { display: block; }
	}

	@include media-breakpoint-up(xl)
	{
		
	}
}

/* circled element */
.circled { @include border-radius(50%); }