/* --------------------------------
   ingredients
-------------------------------- */

.ingredients
{
	$itemMarginBottom: 30px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $itemMarginBottom;
		padding: 30px 0;
		background-color: #fbfbfb;
	}

	.__image
	{
		position: relative;
		flex: 0 0 40%;
		width: 40%;
		margin: auto;
		overflow: hidden;

		&:before
		{
			content: "";
			display: block;
			padding-bottom: 95%;
		}

		img
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 90%;
			max-height: 90%;
			margin: auto;
		}
	}

	.__content
	{
		flex: 1 0 60%;
		padding: 0 15px 30px 0;
	}

	.__title { margin-bottom: 5px; }

	.product-price
	{
		&__item { font-size: 2rem; }
	}

	.__btn
	{
		position: absolute;
		bottom: 10px;
		right: 10px;
	}

	.__add-to-cart
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		min-height: 40px;
		padding: 10px;
		line-height: 1.3;
		font:
		{
			size: rem-calc(16px);
			family: $fontFamily-primary;
			weight: 600;
		};
		text:
		{
			align: center !important;
			decoration: none !important;
			shadow: none !important;
		};
		letter-spacing: 0;
		color: $primary-color;
		background-color: $white;
		@include border-radius(0);
		box-shadow: none;
		outline: none;
		cursor: pointer;
		user-select: none;
		-webkit-user-drag: none;
		user-drag: none;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		z-index: 0;
		@include transition(
			background-color 0.25s ease-in-out,
			border-color     0.25s ease-in-out,
			color            0.25s ease-in-out
		);

		i
		{
			display: inline-block;
			vertical-align: top;
			margin: -5px 10px -5px 0;
			line-height: 1;
			font-size: 2.5rem;
		}

		&:hover,
		&:focus
		{
			background-color: $primary-color;
			color: $white;
		}
	}

	.product-label
	{
		left: 0;
		right: auto;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}