/* --------------------------------
   promo banner
-------------------------------- */

.promo-banners
{
	.row
	{
		margin-left: -5px;
		margin-right: -5px;

		> .col,
		> [class*=col-]
		{
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.__inner { }

	.__item
	{
		display: block;
		margin: 10px 0;
	}
}

.promo-banner
{
	&__inner
	{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 230px;
		padding: 40px 0;
	}

	.__title
	{
		color: $white;

		&:first-child { margin-top: -0.2em; }
	}
}

.promo-banner--s1
{
	background-color: $primary-color;
	color: #ffb9ad;

	.__title
	{
		margin-bottom: 10px;

		&:first-child { margin-top: -0.15em; }
	}

	p
	{
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@include media-breakpoint-up(lg)
	{
		background-image: url(../img/promo_banner_1_bg.jpg);
		background-repeat: no-repeat;
		background-position: center right;
	}

	&.promo-banner--with-offset
	{
		@include min-screen(1500px)
		{
			margin-left: 140px;

			.promo-banner__inner { margin-left: -140px; }
		}
	}
}

.promo-banner--s2
{
	background: url(../img/promo_banner_2_bg.jpg) center left 5%/cover no-repeat #214d88;
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}