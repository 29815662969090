/* --------------------------------
   posts
-------------------------------- */

.posts
{
	$itemMarginBottom: 30px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		position: relative;
		width: 100%;

		.__content
		{
			position: relative;
			line-height: 1.6;
			font-size: 1.6rem;
		}

		.__category
		{
			margin-bottom: 3px;
			color: $primary-color;
		}

		.__title
		{
			margin-top: 3px;
			text-transform: none;

			&:not(:last-child) { margin-bottom: 10px; }
		}
	}

	.__item--preview
	{
		margin-bottom: $itemMarginBottom;
		padding-bottom: 30px;
		background-color: $white;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		@include transition(box-shadow 300ms ease-in-out);

		@include media-breakpoint-up(sm)
		{
			display: flex;
			flex-direction: column;
			align-items: center;

			.__header { flex: 0 0 auto; }

			.__body { flex: 1 0 auto; }

			.__footer { flex: 0 0 auto; }
		}

		> div { width: 100%; }

		.__header {}

		.__body
		{
			.__content { padding-top: 30px; }
		}

		.__footer
		{
			.__content { padding-top: 10px; }
		}

		.__image
		{
			position: relative;
			width: 100%;
			margin: auto;
			overflow: hidden;

			&:before
			{
				content: "";
				display: block;
				padding-top: percentage(210/270);
			}

			img
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include object-fit(cover, 50% 50%);
				@include transition(
					transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
					opacity 200ms
				);
			}
		}

		.__content
		{
			padding-left: 15px;
			padding-right: 15px;

			@include media-breakpoint-up(sm)
			{
				padding-left: 25px;
				padding-right: 25px;
			}
		}

		p
		{
			margin-top: 10px;
			margin-bottom: 10px;

			&:first-child { margin-top: 0; }
			&:last-child  { margin-bottom: 0; }
		}

		&:hover
		{
			.__image img { transform: scale(1.2) translateZ(0); }
		}
	}

	.__item--shadow
	{
		box-shadow: 0px 0px 29px 0px rgba(#aeafaf, 0.17);

		&:hover { box-shadow: 0px 0px 29px 0px rgba(#aeafaf, 0.44); }
	}
}

.post-meta
{
	line-height: 0;
	font-size: 0;
	letter-spacing: -1px;

	&__item
	{
		display: inline-block;
		margin-left: 20px;
		line-height: 1.2;
		font-size: 1.4rem;
		letter-spacing: 0;
		color: #b1b1b1;

		&:first-child { margin-left: 0 }

		a { color: inherit; }
	}

	.__comment-post
	{
		i
		{
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			line-height: 1;
			font-size: 1.2rem;
		}
	}
}

.post-author
{
	&__img
	{
		width: 50px;
		margin-right: 15px;
		overflow: hidden;
		border-radius: 50%;
	}

	&__name
	{
		display: block;
		font-size: 2rem;
		font-weight: 700;
		color: #333;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}