/* --------------------------------
   product single
-------------------------------- */

.product-single
{
	.__gallery
	{
		position: relative;
		background-color: #fbfbfb;

		.__img
		{
			position: relative;
			width: 100%;
			height: 0;
			margin: auto;
			padding-top: percentage(665/470);
			overflow: hidden;

			img
			{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				max-width: 100%;
				max-height: 100%;
				margin: auto;
				// border-top: 65px solid transparent;
				// border-bottom: 65px solid transparent;
			}
		}

		.slick-dots
		{
			position: absolute;
			bottom: 35px;
			left: 0;
			right: 0;
			text-align: center;
		}
	}

	.__name
	{
		margin-bottom: 5px;

		&:first-child { margin-top: -0.25em; }
	}

	.__categories
	{
		line-height: 1.5;
		font-size: 1.4rem;
		font-weight: 700;
		color: #c8c8c8;
		text-transform: uppercase;

		span { color: $primary-color; }
	}

	.__review
	{
		margin-top: 25px;
		line-height: 1;
		font-size: 1.6rem;
	}

	.product-price
	{
		margin-top: 35px;
		margin-left: -20px;
		margin-bottom: 35px;

		&__item
		{
			margin-left: 20px;
			font-size: 3rem;

			&--new {  }
			&--old { text-decoration: line-through; }
		}
	}

	.__add-to-cart
	{
		max-width: 350px;

		button[type=submit]
		{
			margin-top: 0;
			margin-left: -2px;
			white-space: nowrap;

			i
			{
				display: inline-block;
				vertical-align: top;
				margin: -6px 0;
				margin-right: 15px;
				line-height: 1;
				font-size: 2.7rem;
				font-weight: 400;
			}
		}
	}

	.quantity-counter
	{
		input
		{
			height: 60px;
			padding-top: 17px;
			padding-bottom: 17px;
		}
	}

	.__add-review
	{
		&.sticky-top { top: 120px; }

		.rating
		{
			margin-top: 30px;
			margin-bottom: 20px;
			direction: rtl;
			text-align: left;

			.__note
			{
				float: left;
				margin-right: 10px;
				font-size: 1.6rem;
				line-height: 1;
				letter-spacing: 0;
				direction: ltr;
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}