/* --------------------------------
   products cards
-------------------------------- */

.products-cards
{
	$itemMarginBottom: 10px;
	$gridGutter: $itemMarginBottom/2;

	.row
	{
		margin-left: -$gridGutter;
		margin-right: -$gridGutter;

		> .col,
		> [class*=col-]
		{
			padding-left: $gridGutter;
			padding-right: $gridGutter;
		}
	}

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $itemMarginBottom;

		&:hover
		{
			.__image { box-shadow: 0px 5px 29px 0px rgba(0, 0, 0, 0.31); }
		}
	}

	.__image
	{
		position: relative;
		width: 100%;
		height: 200px;
		margin: auto;
		overflow: hidden;
		@include transition(box-shadow 300ms ease-in-out);

		@include media-breakpoint-up(sm)
		{
			height: 250px;
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include object-fit(cover, 50% 50%);
		}
	}

	.__content
	{
		position: absolute;
		left: 20px;
		bottom: 20px;

		@include media-breakpoint-up(md)
		{
			left: 30px;
			bottom: 30px;
		}

		@include media-breakpoint-up(lg)
		{
			left: 40px;
		}
	}

	.__title { }

	.__link
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}