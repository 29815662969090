/* --------------------------------
   tags list
-------------------------------- */

$itemGutter: 7px;
$marginTop: $itemGutter;
$marginLeft: $itemGutter;

.tags-list
{
	line-height: 0;
	font-size: 0;
	letter-spacing: -1px;

	&__list
	{
		margin-top: -$marginTop;
		margin-left: -$marginLeft;
	}

	&__item
	{
		display: inline-block;
		vertical-align: top;
		margin-top: $marginTop;
		margin-left: $marginLeft;
	}

	&__link
	{
		display: block;
		padding: 4px 5px;
		border: 1px solid #d9d9d9;
		line-height: 1.2;
		font-size: 1.2rem;
		text-decoration: none;
		letter-spacing: 1px;
		color: #a5a5a5;
		cursor: pointer;
		white-space: nowrap;
		outline: none;
		box-shadow: none;
		-webkit-user-drag: none;
		user-drag: none;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		@include transition(
			background-color 300ms ease-in-out,
			border-color 300ms ease-in-out,
			color 300ms ease-in-out
		);

		&:hover,
		&:focus,
		&.active
		{
			background-color: $primary-color;
			border-color: $primary-color;
			color: $white;
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}