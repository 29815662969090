/* --------------------------------
   widget
-------------------------------- */

.widget
{
	position: relative;
	margin-top: 40px;

	&:first-child
	{
		margin-top: 0;

		.widget-title { margin-top: -0.2em; }
	}

	&-title
	{
		color: $primary-color;

		&:after { content: "."; }
	}

	@include min-screen($lg-width)
	{
		margin-top: 60px;
	}
}

.widget--search
{
	button[type=submit]
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		background: none transparent;
		border: none;
		margin: 0;
		color: $primary-color;

		+ .textfield { padding-right: 25px; }
	}
}

.widget--categories,
.widget--archive
{
	.list
	{
		&__item
		{
			position: relative;
			margin-top: 15px;
			line-height: 1.3;
			font-weight: 700;

			&:first-child { margin-top: 0; }

			&__link
			{
				display: block;
				text-decoration: none;

				&:not(:hover) { color: #333; }

				> span
				{
					margin-left: 15px;
					float: right;
					color: #999;
				}
			}

			&__arrow
			{
				position: absolute;
				top: 1px;
				right: 0;
				width: 20px;
				height: 20px;
				cursor: pointer;
				transform-origin: 50% 50%;
				@include transition(transform 0.3s ease-in-out);

				&:before
				{
					content: "";
					display: block;
					width: 7px;
					height: 7px;
					margin: auto;
					margin-top: 6px;
					border-right: 2px solid;
					border-bottom: 2px solid;
					border-color: #25272a;
					transform-origin: 50% 50%;
					transform: rotate(45deg);
					@include transition(border-color 0.3s ease-in-out);
				}

				&.is-active
				{
					transform: rotate(180deg);

					&:before { border-color: $primary-color; }
				}
			}
		}

		&__submenu
		{
			display: none;
			margin-left: 15px;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;

			.list__item
			{
				margin-top: 13px;

				&__link
				{
					font-size: 1.6rem;
					color: #777;

					&:before
					{
						content: "";
						position: absolute;
						top: 100%;
						left: 0;
						width: 0;
						margin-top: 2px;
						border-top: 2px solid currentColor;
						pointer-events: none;
						@include transition(width 0.3s ease-in-out);
					}

					&:hover,
					&:focus,
					&.active
					{
						color: $primary-color;

						&:before { width: 30px; }
					}
				}
			}
		}
	}
}

.widget--posts
{
	article
	{
		margin-top: 20px;
		line-height: 1.4;

		&:first-child { margin-top: 0; }
	}

	.__image-wrap
	{
		width: 33%;
		max-width: 100px;
		padding-right: 15px;
	}

	.__image
	{
		position: relative;
		width: 100%;
		height: 0;
		margin: auto;
		padding-top: 100%;
		overflow: hidden;

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include object-fit(cover, 50% 50%);
		}
	}

	.__title
	{
		margin-bottom: 10px;
		font-size: 1.8rem;
	}
}

.widget--tags
{
	
}

.widget--instagram
{
	.widget-title
	{
		&:after { content: none; }

		i
		{
			float: right;
			color: #333;
		}
	}

	.instafeed
	{
		margin: 0 !important;

		.row
		{
			margin-left: -5px;
			margin-right: 0;

			> .col,
			> [class*=col-]
			{
				padding-left: 5px;
				padding-right: 0;
			}
		}

		.__item
		{
			margin-top: 5px;
		}

		.__image
		{
			min-height: 1px;
			padding-top: 100%;
		}
	}
}

.widget--price
{

}

.widget--brands
{
	font-weight: 700;
	color: #25272a;

	ul { line-height: 1; }

	li
	{
		margin-top: 20px;

		&:first-child { margin-top: 0; }
	}

	.checkfield { margin-bottom: 0; }
}

.widget--colors
{
	ul
	{
		margin-top: -10px;
		margin-left: -10px;
		line-height: 0;
		font-size: 0;
		letter-spacing: -1px;
	}

	li
	{
		display: inline-block;
		vertical-align: top;
		margin-top: 10px;
		margin-left: 10px;
	}

	.color-label
	{
		display: block;

		input[type=checkbox]
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			visibility: hidden;
			opacity: 0;
			clip: rect(2px,2px,2px,2px);

			&:checked ~ span:before { transform: scale(1); }
		}

		span
		{
			position: relative;
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			cursor: pointer;

			&:before
			{
				content: "";
				position: absolute;
				top: 5px;
				right: 5px;
				bottom: 5px;
				left: 5px;
				border-radius: inherit;
				background-color: $white;
				transform: scale(0);
				@include transition(transform .25s cubic-bezier(.23,1,.32,1));
			}
		}
	}
}

.widget--characteristics
{
	font-weight: 700;
	color: #25272a;

	ul { line-height: 1; }

	li
	{
		margin-top: 20px;

		&:first-child { margin-top: 0; }
	}

	.radiofield { margin-bottom: 0; }
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{

}