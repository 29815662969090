/* --------------------------------
   quantity counter
-------------------------------- */

.quantity-counter
{
	position: relative;

	input
	{
		display: block;
		float: none;
		width: 100%;
		height: 50px;
		background: padding-box none transparent;
		border: 2px solid #f1f1f1;
		line-height: 1.2;
		font-size: 1.8rem;
		text-align: center;
		color: #777;
		appearance: none;
		outline: none;
		padding: 0 38px;
		box-shadow: none;
		border-radius: 0;
		@include transition(
			background-color 0.3s ease-in-out,
			border-color 0.3s ease-in-out,
			color 0.3s ease-in-out
		);
	}

	.__btn
	{
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 30px;
		height: 30px;
		line-height: 30px;
		font-size: 2.8rem;
		text-align: center;
		color: #333;
		cursor: pointer;
		user-select: none;

		&:before,
		&:after
		{
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: currentColor;
		}

		&--minus
		{
			left: 5px;

			&:before
			{
				content: "";
				margin-top: -1px;
				margin-left: -6px;
				width: 12px;
				height: 2px;
			}
		}

		&--plus
		{
			right: 5px;

			&:before
			{
				content: "";
				margin-top: -1px;
				margin-left: -6px;
				width: 12px;
				height: 2px;
			}

			&:after
			{
				content: "";
				margin-top: -6px;
				margin-left: -1px;
				width: 2px;
				height: 12px;
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	.quantity-counter
	{
		.__btn
		{
			&--minus { left: 10px; }
			&--plus { right: 10px; }
		}
	}
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}