/* --------------------------------
   gallery
-------------------------------- */

.gallery
{
	$itemSize: percentage(360/420);

	margin-bottom: 70px;

	&:last-child { margin-bottom: 0; }

	&-filter
	{
		margin-left: -30px;
		margin-bottom: 40px;
		line-height: 0;
		font-size: 0;
		letter-spacing: -1px;

		li
		{
			display: inline-block;
			vertical-align: middle;
			margin-left: 30px;
			margin-bottom: 20px;
			letter-spacing: 0;
		}

		a
		{
			display: block;
			padding-bottom: 5px;
			border-bottom: 2px solid transparent;
			line-height: 1.2;
			font-size: 1.6rem;
			font-weight: 700;
			text-decoration: none;
			color: #b2b2b2;

			&:hover,
			&.selected
			{
				color: #25272a;
			}

			&.selected
			{
				border-color: $primary-color;
			}
		}
	}

	&--grid
	{
		.__image
		{
			min-height: 200px;
			padding-top: $itemSize;
		}
	}

	&--slider
	{
		overflow: hidden;

		.slick-slider
		{
			margin-left: -5px;
			margin-right: -5px;
			margin-bottom: 10px;

			@include media-breakpoint-up(sm)
			{
				margin-right: -10%;
			}
		}

		.slick-slide
		{
			padding-left: 5px;
			padding-right: 5px;
		}

		.__image
		{
			min-height: 300px;

			@include media-breakpoint-up(sm)
			{
				min-height: 360px;
			}
		}
	}

	.__inner {}

	.__item
	{
		position: relative;
		vertical-align: top;
		width: 100%;

		@include media-breakpoint-up(sm)
		{
			&[data-x="2"][data-y="1"] .__image
			{
				padding-top: $itemSize/2;
			}

			&[data-x="1"][data-y="2"] .__image
			{
				padding-top: $itemSize*2;
			}
		}

		&:hover
		{
			.__image
			{
				&:after { opacity: 0.7; }
			}

			.__content { background-color: $primary-color; }

			.__link
			{
				opacity: 1;
				transform: scale(1) translateZ(0);
				transition-delay: 100ms;
			}
		}
	}

	.__image
	{
		position: relative;
		width: 100%;
		height: 0;
		margin: auto;
		overflow: hidden;

		&:after,
		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&:after
		{
			content: "";
			background-color: #2c2c2c;
			opacity: 0;
			@include transition(opacity 300ms ease-in-out);
		}

		img { @include object-fit(cover, 50% 50%); }
	}

	.__content
	{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 20px 15px;
		background-color: transparent;
		color: $white;
		@include transition(background-color 300ms ease-in-out);

		@include media-breakpoint-up(sm)
		{
			padding: 25px 30px;
		}
	}

	.__title,
	.__link
	{
		color: inherit;
	}

	.__link
	{
		display: block;
		width: 1em;
		line-height: 1;
		font-size: 2.3rem;
		opacity: 0;
		transform: scale(0.4) translateZ(0);
		@include transition(
			opacity 300ms ease-in-out,
			transform 300ms ease-in-out
		);
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}