/* --------------------------------
   info table
-------------------------------- */

.info-table
{
	padding: 0 1px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;

	table
	{
		min-width: 400px;
		line-height: 1.4;
		font-size: 1.6rem;

		tr {}

		td
		{
			padding: 15px 10px;
			height: 55px;

			&:first-child { padding-left: 20px; }
			&:last-child { padding-right: 20px; }
		}
	}

	thead
	{
		color: #25272a;

		tr { }

		td { font-weight: 700; }
	}

	tbody
	{
		tr {}

		td
		{
			&:first-child { font-weight: 700; }
		}
	}
}

.info-table--s1
{
	thead { border-bottom: 1px solid #e3e3e3; }

	tbody
	{
		tr
		{
			&:nth-of-type(even) { background-color: #f9f9f9; }
		}
	}
}

.info-table--s2
{
	thead
	{
		background-color: #2e2e2e;
		color: $white;
	}

	tbody
	{
		tr { border-bottom: 1px solid #e3e3e3; }
	}
}

.info-table--s3
{
	thead
	{
		background-color: #e3e3e3;
		border: 1px solid #e3e3e3
	}

	tbody
	{
		tr
		{
			&:nth-of-type(even) { background-color: #f9f9f9; }
		}

		td { border: 1px solid #e3e3e3; }
	}
}

.info-table--s4
{
	thead { border-bottom: 1px solid #e3e3e3; }

	tbody
	{
		tr
		{
			&:nth-of-type(even) { border: 1px solid #e3e3e3; }
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}