/* --------------------------------
   services
-------------------------------- */

.services
{
	$itemMarginBottom: 45px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $itemMarginBottom;

		&:hover
		{
			.__content
			{
				&:before { transform: translateY(0%); }

				&:after { color: $white; }
			}
		}
	}

	.__image
	{
		position: relative;
		width: 100%;
		margin: auto;
		max-height: 350px;
		overflow: hidden;

		@include media-breakpoint-up(sm)
		{
			max-height: 420px;
		}

		&:before
		{
			content: "";
			display: block;
			padding-top: percentage(430/290);
		}

		&:after
		{
			content: "";
			background-color: rgba(#040404, 0.34);
		}

		&:after,
		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		img { @include object-fit(cover, 50% 50%); }
	}

	.__content
	{
		$borderWidth: 7px;

		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 25px 15px (25px-$borderWidth);
		border-bottom: $borderWidth solid $primary-color;
		color: $white;

		@include media-breakpoint-up(md)
		{
			padding-left: 25px;
			padding-right: 25px;
		}

		&:before
		{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $primary-color;
			transform: translateY(100%);
			@include transition(transform 300ms ease-in-out);
		}

		&:after
		{
			content: attr(data-num);
			display: block;
			position: absolute;
			top: 0;
			left: auto;
			line-height: 1;
			font-size: 4rem;
			font-weight: 700;
			color: $primary-color;
			transform: translateY(-55%);
			@include transition(color 300ms ease-in-out);
		}
	}

	.__title,
	.__title a
	{
		position: relative;
		color: inherit;
	}
}

.services--slider
{
	$slideGutter: 10px;

	@if $slideGutter > 0
	{
		.slick-list
		{
			margin-left: -$slideGutter/2;
			margin-right: -$slideGutter/2;
		}

		.slick-slide
		{
			padding-left: $slideGutter/2;
			padding-right: $slideGutter/2;
		}
	}

	.__item
	{
		vertical-align: top;
		margin-bottom: 0;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}