/* --------------------------------
   side menu
-------------------------------- */

.side-menu
{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 260px;
	background-color: #25272a;
	padding: 135px + 50px 30px 50px;
	font-size: 1.6rem;
	font-weight: 700;
	color: #d3d3d3;
	overflow: hidden;
	backface-visibility: hidden;
	transform: translateX(-100%);
	z-index: 4;
	@include transition(transform 400ms ease-in-out);

	&.is-active { transform: translateX(0%); }

	&__inner
	{
		position: relative;
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: scrollbar;
	}

	&__menu
	{
		margin-top: 9.5vh;
		margin-bottom: 9.5vh;
		line-height: 1.4;

		li
		{
			margin-top: 20px;
			font-size: 1.4rem;
			font-weight: 700;
			text-transform: uppercase;

			&:first-child { margin-top: 0; }

			&.active > a,
			> a:hover,
			> a:focus
			{
				color: #6e6e6e;

				&:after
				{
					width: 45px;
				}
			}
		}

		a
		{
			position: relative;
			color: inherit;
			text-decoration: none;

			&:after
			{
				content: "";
				pointer-events: none;
				position: absolute;
				top: 100%;
				left: 0;
				width: 0;
				margin-top: 5px;
				border-top: 2px solid $primary-color;
				@include transition(
					width 0.3s ease-in-out
				);
			}
		}
	}

	&__contacts
	{
		margin-top: 9.5vh;
		margin-bottom: 9.5vh;
	}

	.s-btns { margin-top: 20px; }
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}