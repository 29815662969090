/* --------------------------------
   video block
-------------------------------- */

.video-block
{
	.__bg
	{
		display: flex;
		align-items: center;
		height: 290px;
		padding: 30px 15px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.btn_play
	{
		display: inline-block;
		vertical-align: top;
		line-height: 1.4;
		font-size: 1.5rem;
		font-weight: 600;
		text-decoration: none;

		i
		{
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 56px;
			height: 56px;
			margin-right: 20px;

			&:before,
			&:after
			{
				content: "";
				position: absolute;
				@include transition(
					background-color 0.3s ease-in-out,
					border-color 0.3s ease-in-out,
					transform 0.4s ease-in-out
				);
			}

			&:before
			{
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}

			&:after
			{
				top: 0;
				right: 0;
				bottom: 0;
				left: 3px;
				margin: auto;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 7px 0 7px 9px;
				border-color: transparent transparent transparent $primary-color;
			}
		}

		&--white
		{
			color: $white;

			i
			{
				&:before { background-color: $white; }
			}

			&:hover,
			&:focus
			{
				i
				{
					&:before { background-color: $primary-color; }
					&:after { border-color: transparent transparent transparent $white; }
				}
			}
		}

		&--dark
		{
			color: #333;

			i
			{
				&:before { background-color: #25272a; }
			}
		}

		&:hover,
		&:focus
		{
			i
			{
				&:before { transform: scale(1.25); }
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	.video-block
	{
		.__bg { padding: 30px; }
	}
}

@include media-breakpoint-up(md)
{
	.video-block
	{
		.__bg
		{
			.btn_play { margin-left: 10%; }
		}
	}
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}