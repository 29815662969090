/* --------------------------------
   goods rent
-------------------------------- */

.goods-rent
{
	$itemMarginBottom: 45px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $itemMarginBottom;
		background-color: $white;
	}

	.__image
	{
		position: relative;
		width: 100%;
		min-height: 270px;
		max-height: 380px;
		margin: auto;
		overflow: hidden;

		&:before
		{
			content: "";
			display: block;
			padding-top: percentage(370/270);
		}

		img
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 90%;
			max-height: 90%;
			margin: auto;
			border-top: 30px solid transparent;

			@include media-breakpoint-up(md)
			{
				border-top-width: 50px;
			}
		}
	}

	.__content
	{
		padding: 20px 15px 55px;
		text-align: center;
	}

	.__price
	{
		margin-left: -20px;
		margin-bottom: 30px;
		line-height: 0;
		font-size: 0;
		letter-spacing: -1px;

		&:last-child { margin-bottom: 0; }

		span
		{
			display: inline-block;
			vertical-align: top;
			margin-left: 20px;
			line-height: 1;
			font-size: 3rem;
			font-weight: 700;

			sub
			{
				bottom: auto;
				font-size: percentage(2rem/3rem);
			}
		}

		.__new { color: $primary-color; }
		.__old { color: #c5c5c5; }
	}

	.__title { }
}

.goods-rent--slider
{
	$slideGutter: 0;

	.slider-special-layout__inner { box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.05); }

	.slick-track { background-color: $white; }

	@if $slideGutter > 0
	{
		.slick-list
		{
			margin-left: -$slideGutter/2;
			margin-right: -$slideGutter/2;
		}

		.slick-slide
		{
			padding-left: $slideGutter/2;
			padding-right: $slideGutter/2;
		}
	}

	.__item
	{
		vertical-align: top;
		margin-bottom: 0;

		&:before
		{
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			border-left: 1px solid #eff2f5;
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}