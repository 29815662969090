/* --------------------------------
   product label
-------------------------------- */

.product-label
{
	position: absolute;
	top: 0;
	right: 0;
	min-width: 65px;
	padding: 7px 15px;
	line-height: 1;
	font-size: 1.6rem;
	font-weight: 600;
	text-align: center;
	color: $white;
	user-select: none;

	&--new      { background-color: #4cdcae; }
	&--discount { background-color: #f1492c; }
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}