/* --------------------------------
   contact info
-------------------------------- */

.contact-info
{
	line-height: 1;
	text-align: left;

	>li
	{
		margin-top: 20px;
		line-height: 1.4;
		font-size: 1.5rem;
		font-weight: 600;
		font-style: normal;
		color: #d3d3d3;

		&:first-child { margin-top: 0; }
	}

	.__label
	{
		margin-bottom: 10px;
		line-height: 1.2;
		font-size: 1.2rem;
		font-weight: 700;
		text-transform: uppercase;
		color: #5c5c5c;
	}

	a:not([class]) { color: inherit; }
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}