/* --------------------------------
   product brochure
-------------------------------- */

.product-brochure
{
	padding: 30px 15px;
	background: #2a6ab7 url(../img/product_brochure_bg.png) no-repeat -145px -15px;
	line-height: 1.4;
	font-weight: 700;
	text-align: right;
	color: $white;

	&__inner
	{
		display: inline-block;
		vertical-align: top;
		text-align: left;
	}

	.custom-btn
	{
		border-color: currentColor;
		color: $white;

		&:hover,
		&:focus
		{
			background-color: $white;
			border-color: $white;
			color: #333;
		}
	}
}

@media (min-width: 400px)
{
	.product-brochure
	{
		background-position: -80px -15px;
	}
}

@include media-breakpoint-up(sm)
{
	.product-brochure { padding: 40px 30px; }
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}