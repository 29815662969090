/* --------------------------------
   tab
-------------------------------- */

$borderWidth: 2px;
$borderColor: #e4e4e4;

.tab
{
	&-container
	{
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: $white;

		&--hor
		{
			@include media-breakpoint-up(md)
			{
				.tab-nav--top { margin-bottom: -2px; }

				.tab-nav__list { flex-direction: row; }

				.tab-nav__item
				{
					margin-left: -$borderWidth;

					&:first-child { margin-left: 0; }
				}
			}
		}

		&--ver
		{
			@include media-breakpoint-up(md)
			{
				flex-direction: row;

				.tab-nav--left { margin-right: -2px; }

				.tab-nav__item
				{
					margin-top: -$borderWidth;

					&:first-child { margin-top: 0; }
				}
			}
		}
	}

	&-nav
	{
		position: relative;
		line-height: 0;
		font-size: 0;
		letter-spacing: -1px;
		z-index: 3;

		&__list
		{
			display: flex;
			flex-direction: column;
			min-height: 100%;
		}

		&__item
		{
			position: relative;
			display: inline-block;
			vertical-align: middle;
			border: $borderWidth solid transparent;
			line-height: 1;
			cursor: pointer;
			@include transition(all 0.3s ease-in-out);

			&.active
			{
				cursor: default;

				.tab-nav__link { cursor: default; }
			}
		}

		&__link
		{
			display: block;
			line-height: 36px;
			font-size: 1.8rem;
			font-weight: 700;
			color: #a3a3a3;
			text-decoration: none !important;
			letter-spacing: 0;
			border: none;
			box-shadow: none;
			outline: none;
			user-select: none;
			-webkit-user-drag: none;
			user-drag: none;

			@include media-breakpoint-up(md)
			{
				line-height: 46px;
			}
		}
	}

	&-content
	{
		position: relative;
		flex-grow: 1;
		width: 100%;

		&__item
		{
			@extend %block-absolute--full;
			top: 0;
			left: 0;
			padding-top: 15px;
			visibility: hidden;
			opacity: 0;
			@include transition(
				opacity    300ms ease-in-out,
				visibility 300ms ease-in-out
			);

			&.is-visible
			{
				position: static;
				top: auto;
				left: auto;
				visibility: visible;
				z-index: 2;
				opacity: 1;
			}
		}
	}
}

.tab-container--s1
{
	&.tab-container--hor
	{
		@include media-breakpoint-up(md)
		{
			.tab-nav__item
			{
				&.active { border-bottom-color: $white; }
			}
		}
	}

	&.tab-container--ver
	{
		@include media-breakpoint-up(md)
		{
			.tab-nav__item
			{
				&.active { border-right-color: $white; }
			}
		}
	}

	.tab-nav
	{
		&__item
		{
			&.active { border-color: $borderColor; }

			&.active,
			&:hover
			{
				.tab-nav__link { color: #333; }
			}
		}

		&__link
		{
			padding: 0 20px;

			@include media-breakpoint-up(lg)
			{
				padding: 0 30px;
			}
		}
	}

	.tab-content
	{
		border: $borderWidth solid $borderColor;

		&__item { padding: 20px 15px; }

		@include media-breakpoint-up(md)
		{
			&__item { padding: 35px 30px; }
		}
	}
}

.tab-container--s2
{
	&.tab-container
	{
		.tab-nav__item
		{
			&.active { border-bottom-color: $primary-color; }
		}
	}

	&.tab-container--hor
	{
		.tab-content
		{
			@include media-breakpoint-up(md)
			{
				border-top: $borderWidth solid $borderColor;

				&__item { padding-top: 35px; }
			}
		}
	}

	&.tab-container--ver
	{
		@include media-breakpoint-up(md)
		{
			.tab-nav__item
			{
				&.active
				{
					border-right-color: $primary-color;
					border-bottom-color: transparent;
				}
			}

			.tab-content
			{
				border-left: $borderWidth solid $borderColor;

				&__item
				{
					padding-top: 0;
					padding-left: 30px;
				}
			}
		}
	}

	.tab-nav
	{
		&__item
		{
			&.active,
			&:hover
			{
				.tab-nav__link { color: $primary-color; }
			}
		}

		&__link { padding: 0 18px; }
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}