/* --------------------------------
   checkout
-------------------------------- */

.checkout
{
	&__form {}

	&__table
	{
		margin-top: 30px;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		&:first-child { margin-top: 0; }

		table { min-width: 380px; }

		tr
		{
			&:first-child
			{
				td { padding-top: 0; }
			}
		}

		td
		{
			padding-top: 20px;
			padding-left: 20px;

			&:first-child { padding-left: 0; }
		}

		.__image
		{
			position: relative;
			width: 120px;
			height: 120px;
			background-color: #fbfbfb;
			margin-right: 15px;

			@include media-breakpoint-up(lg)
			{
				margin-right: 35px;
			}

			a
			{
				display: block;
				width: 100%;
				height: 100%;
			}

			img
			{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				max-width: 90%;
				max-height: 80%;
			}
		}

		.__name
		{
			display: inline-block;
			vertical-align: top;
			line-height: 1.4;
			margin-bottom: 5px;
			font-size: 1.8rem;
			font-weight: 700;
			text-decoration: none;

			&:last-child { margin-bottom: 0; }

			&:not(:hover):not(:focus) { color: #333; }
		}

		.__amount
		{
			line-height: 1.2;
			font-size: 1.6rem;
			font-weight: 700;
			color: #999;
		}

		.__total
		{
			font-weight: 700;
			color: $primary-color;
			white-space: nowrap;
		}
	}

	&__total
	{
		width: 100%;
		max-width: 370px;
		margin-top: 50px;
		padding-top: 40px;
		border-top: 1px solid #f0f0f0;

		line-height: 1.3;
		font-weight: 700;
		color: #333;

		tbody
		{
			tr { }

			td { padding-bottom: 10px; }
		}

		tfoot
		{
			tr { }

			td { padding-top: 10px; }
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}