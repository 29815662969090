.jarallax {
  position: relative;
  z-index: 0
}
.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1
}
body.compensate-for-scrollbar {
  overflow: hidden
}
.fancybox-active {
  height: auto
}
.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute!important;
  top: -9999px;
  visibility: hidden
}
.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992
}
.fancybox-container * {
  box-sizing: border-box
}
.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}
.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto
}
.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.47,0,.74,.71)
}
.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(.22,.61,.36,1)
}
.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s,visibility .25s;
  visibility: hidden;
  z-index: 99997
}
.fancybox-show-caption .fancybox-caption,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-nav .fancybox-navigation .fancybox-button,
.fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity .25s,visibility;
  visibility: visible
}
.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.fancybox-toolbar {
  right: 0;
  top: 0
}
.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994
}
.fancybox-is-open .fancybox-stage {
  overflow: hidden
}
.fancybox-slide {
  -webkit-backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform,opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994
}
.fancybox-slide:before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0
}
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
  display: block
}
.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0
}
.fancybox-slide--image:before {
  display: none
}
.fancybox-slide--html {
  padding: 6px
}
.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle
}
.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(.5,0,.14,1);
  -webkit-backface-visibility: hidden;
  background: 0 0/100% 100% no-repeat;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  transform-origin: top left;
  transition-property: transform,opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995
}
.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out
}
.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in
}
.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
  cursor: grab
}
.fancybox-is-grabbing .fancybox-content {
  cursor: grabbing
}
.fancybox-container [data-selectable=true] {
  cursor: text
}
.fancybox-image,
.fancybox-spaceball {
  background: 0 0;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%
}
.fancybox-spaceball {
  z-index: 1
}
.fancybox-slide--iframe .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--video .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%
}
.fancybox-slide--video .fancybox-content {
  background: #000
}
.fancybox-slide--map .fancybox-content {
  background: #e5e3df
}
.fancybox-slide--iframe .fancybox-content {
  background: #fff
}
.fancybox-iframe,
.fancybox-video {
  background: 0 0;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%
}
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0
}
.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%
}
.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0
}
.fancybox-button {
  background: rgba(30,30,30,.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px
}
.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
  color: #ccc
}
.fancybox-button:hover {
  color: #fff
}
.fancybox-button:focus {
  outline: 0
}
.fancybox-button.fancybox-focus {
  outline: dotted 1px
}
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: 0
}
.fancybox-button div {
  height: 100%
}
.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%
}
.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0
}
.fancybox-button--fsenter svg:nth-child(2),
.fancybox-button--fsexit svg:first-child,
.fancybox-button--pause svg:first-child,
.fancybox-button--play svg:nth-child(2) {
  display: none
}
.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998
}
.fancybox-close-small {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401
}
.fancybox-close-small:hover {
  color: #fff;
  opacity: 1
}
.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden
}
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none
}
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px
}
.fancybox-navigation .fancybox-button div {
  padding: 7px
}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px
}
.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right)
}
.fancybox-caption {
  background: linear-gradient(0deg,rgba(0,0,0,.85) 0,rgba(0,0,0,.3) 50%,rgba(0,0,0,.15) 65%,rgba(0,0,0,.075) 75.5%,rgba(0,0,0,.037) 82.85%,rgba(0,0,0,.019) 88%,transparent);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996
}
@supports (padding:max(0px)) {
  .fancybox-caption {
    padding: 75px max(44px,env(safe-area-inset-right)) max(25px,env(safe-area-inset-bottom)) max(44px,env(safe-area-inset-left))
  }
}
.fancybox-caption--separate {
  margin-top: -50px
}
.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all
}
.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none
}
.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline
}
.fancybox-loading {
  animation: 1s linear infinite a;
  background: 0 0;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999
}
@keyframes a {
  to {
    transform: rotate(1turn)
  }
}
.fancybox-animated {
  transition-timing-function: cubic-bezier(0,0,.25,1)
}
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%,0,0)
}
.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%,0,0)
}
.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translateZ(0)
}
.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(.19,1,.22,1)
}
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1
}
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5,1.5,1.5)
}
.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(.5,.5,.5)
}
.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1)
}
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  transform: rotate(-1turn)
}
.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  transform: rotate(1turn)
}
.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  transform: rotate(0)
}
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0,0,0) translate3d(-100%,0,0)
}
.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0,0,0) translate3d(100%,0,0)
}
.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1) translateZ(0)
}
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%,0,0) scale(.1) skew(-10deg)
}
.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%,0,0) scale(.1) skew(10deg)
}
.fancybox-fx-tube.fancybox-slide--current {
  transform: translateZ(0) scale(1)
}
@media (max-height:576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px
  }
  .fancybox-slide--image {
    padding: 6px 0
  }
  .fancybox-close-small {
    right: -6px
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px
  }
  @supports (padding:max(0px)) {
    .fancybox-caption {
      padding-left: max(12px,env(safe-area-inset-left));
      padding-right: max(12px,env(safe-area-inset-right))
    }
  }
}
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center
}
.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px
}
.fancybox-share p {
  margin: 0;
  padding: 0
}
.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap
}
.fancybox-share__button:link,
.fancybox-share__button:visited {
  color: #fff
}
.fancybox-share__button:hover {
  text-decoration: none
}
.fancybox-share__button--fb {
  background: #3b5998
}
.fancybox-share__button--fb:hover {
  background: #344e86
}
.fancybox-share__button--pt {
  background: #bd081d
}
.fancybox-share__button--pt:hover {
  background: #aa0719
}
.fancybox-share__button--tw {
  background: #1da1f2
}
.fancybox-share__button--tw:hover {
  background: #0d95e8
}
.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px
}
.fancybox-share__button svg path {
  fill: #fff
}
.fancybox-share__input {
  background: 0 0;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: 0;
  padding: 10px 15px;
  width: 100%
}
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995
}
.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden
}
.fancybox-show-thumbs .fancybox-thumbs {
  display: block
}
.fancybox-show-thumbs .fancybox-inner {
  right: 212px
}
.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: 100%
}
.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3)
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px
}
.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0,0,0,.1);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px
}
.fancybox-thumbs__list a:before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .2s cubic-bezier(.25,.46,.45,.94);
  z-index: 99991
}
.fancybox-thumbs__list a:focus:before {
  opacity: .5
}
.fancybox-thumbs__list a.fancybox-thumbs-active:before {
  opacity: 1
}
@media (max-width:576px) {
  .fancybox-thumbs {
    width: 110px
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px)
  }
}
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: 40px;
  outline: 0;
  padding-left: 18px;
  position: relative;
  text-align: left!important;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto
}
.nice-select:hover {
  border-color: #dbdbdb
}
.nice-select.open,
.nice-select:active,
.nice-select:focus {
  border-color: #999
}
.nice-select:after {
  content: '';
  display: block;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg)
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0)
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none
}
.nice-select.disabled:after {
  border-color: #ccc
}
.nice-select.wide {
  width: 100%
}
.nice-select.wide .list {
  left: 0!important;
  right: 0!important
}
.nice-select.right {
  float: right
}
.nice-select.right .list {
  left: auto;
  right: 0
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px
}
.nice-select.small:after {
  height: 4px;
  width: 4px
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px
}
.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68,68,68,.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(.75) translateY(-21px);
  -ms-transform: scale(.75) translateY(-21px);
  transform: scale(.75) translateY(-21px);
  -webkit-transition: .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
  transition: .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
  z-index: 9
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent!important
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  list-style: none;
  min-height: 40px;
  outline: 0;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: .2s;
  transition: .2s
}
.nice-select .option.selected {
  font-weight: 700
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default
}
.no-csspointerevents .nice-select .list {
  display: none
}
.no-csspointerevents .nice-select.open .list {
  display: block
}
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-family: Arial,sans-serif
}
.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: 0!important
}
.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0
}
.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0
}
.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1
}
.irs-handle.type_last {
  z-index: 2
}
.irs-max,
.irs-min {
  position: absolute;
  display: block;
  cursor: default
}
.irs-min {
  left: 0
}
.irs-max {
  right: 0
}
.irs-from,
.irs-single,
.irs-to {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap
}
.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px
}
.irs-with-grid .irs-grid {
  display: block
}
.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000
}
.irs-grid-pol.small {
  height: 4px
}
.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000
}
.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0,0,0,0);
  z-index: 2
}
.lt-ie9 .irs-disable-mask {
  background: #000;
  cursor: not-allowed
}
.irs-disabled {
  opacity: .4
}
.irs-hidden-input {
  position: absolute!important;
  display: block!important;
  top: 0!important;
  left: 0!important;
  width: 0!important;
  height: 0!important;
  font-size: 0!important;
  line-height: 0!important;
  padding: 0!important;
  margin: 0!important;
  overflow: hidden;
  outline: 0!important;
  z-index: -9999!important;
  background: 0 0!important;
  border-style: solid!important;
  border-color: transparent!important
}
.irs--flat {
  height: 40px
}
.irs--flat.irs-with-grid {
  height: 60px
}
.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px
}
.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: #ed5565
}
.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px
}
.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e1e4e9
}
.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #da4453
}
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540
}
.irs--flat .irs-max,
.irs--flat .irs-min {
  top: 0;
  padding: 1px 3px;
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px
}
.irs--flat .irs-from,
.irs--flat .irs-single,
.irs--flat .irs-to {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ed5565;
  border-radius: 4px
}
.irs--flat .irs-from:before,
.irs--flat .irs-single:before,
.irs--flat .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565
}
.irs--flat .irs-grid-pol {
  background-color: #e1e4e9
}
.irs--flat .irs-grid-text {
  color: #999
}
.irs--big {
  height: 55px
}
.irs--big.irs-with-grid {
  height: 70px
}
.irs--big .irs-line {
  top: 33px;
  height: 12px;
  background-color: #fff;
  background: linear-gradient(to bottom,#ddd -50%,#fff 150%);
  border: 1px solid #ccc;
  border-radius: 12px
}
.irs--big .irs-bar {
  top: 33px;
  height: 12px;
  background-color: #92bce0;
  border: 1px solid #428bca;
  background: linear-gradient(to bottom,#fff 0,#428bca 30%,#b9d4ec 100%);
  box-shadow: inset 0 0 1px 1px rgba(255,255,255,.5)
}
.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px
}
.irs--big .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: rgba(66,139,202,.5)
}
.irs--big .irs-handle {
  top: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0,0,0,.3);
  background-color: #cbcfd5;
  background: linear-gradient(to bottom,#fff 0,#b4b9be 30%,#fff 100%);
  box-shadow: 1px 1px 2px rgba(0,0,0,.2),inset 0 0 3px 1px #fff;
  border-radius: 30px
}
.irs--big .irs-handle.state_hover,
.irs--big .irs-handle:hover {
  border-color: rgba(0,0,0,.45);
  background-color: #939ba7;
  background: linear-gradient(to bottom,#fff 0,#919ba5 30%,#fff 100%)
}
.irs--big .irs-max,
.irs--big .irs-min {
  top: 0;
  padding: 1px 5px;
  color: #fff;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px
}
.irs--big .irs-from,
.irs--big .irs-single,
.irs--big .irs-to {
  color: #fff;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #428bca;
  background: linear-gradient(to bottom,#428bca 0,#3071a9 100%);
  border-radius: 3px
}
.irs--big .irs-grid-pol {
  background-color: #428bca
}
.irs--big .irs-grid-text {
  color: #428bca
}
.irs--modern,
.irs--modern.irs-with-grid {
  height: 55px
}
.irs--modern .irs-line {
  top: 25px;
  height: 5px;
  background-color: #d1d6e0;
  background: linear-gradient(to bottom,#e0e4ea 0,#d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px
}
.irs--modern .irs-bar {
  top: 25px;
  height: 5px;
  background: #20b426;
  background: linear-gradient(to bottom,#20b426 0,#18891d 100%)
}
.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px
}
.irs--modern .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(209,214,224,.5)
}
.irs--modern .irs-handle {
  top: 37px;
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  box-shadow: 1px 1px 1px rgba(0,0,0,.1);
  border-radius: 0 0 3px 3px
}
.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  display: block;
  top: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #a3adc1;
  background: #fff;
  transform: rotate(45deg)
}
.irs--modern .irs-handle > i:nth-child(2) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 10px;
  height: 12px;
  background: #e9e6e6;
  background: linear-gradient(to bottom,#fff 0,#e9e6e6 100%);
  border-radius: 0 0 3px 3px
}
.irs--modern .irs-handle > i:nth-child(3) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1
}
.irs--modern .irs-handle.state_hover,
.irs--modern .irs-handle:hover {
  border-color: #7685a2;
  background: #c3c7cd;
  background: linear-gradient(to bottom,#fff 0,#919ba5 30%,#fff 100%)
}
.irs--modern .irs-handle.state_hover > i:nth-child(1),
.irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2
}
.irs--modern .irs-handle.state_hover > i:nth-child(3),
.irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a
}
.irs--modern .irs-max,
.irs--modern .irs-min {
  top: 0;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: #fff;
  background-color: #d1d6e0;
  border-radius: 5px
}
.irs--modern .irs-from,
.irs--modern .irs-single,
.irs--modern .irs-to {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #20b426;
  color: #fff;
  border-radius: 5px
}
.irs--modern .irs-from:before,
.irs--modern .irs-single:before,
.irs--modern .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #20b426
}
.irs--modern .irs-grid {
  height: 25px
}
.irs--modern .irs-grid-pol {
  background-color: #dedede
}
.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px
}
.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1
}
.irs--sharp.irs-with-grid {
  height: 57px
}
.irs--sharp .irs-line {
  top: 30px;
  height: 2px;
  background-color: #000;
  border-radius: 2px
}
.irs--sharp .irs-bar {
  top: 30px;
  height: 2px;
  background-color: #ee22fa
}
.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px
}
.irs--sharp .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(0,0,0,.5)
}
.irs--sharp .irs-handle {
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #a804b2
}
.irs--sharp .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #a804b2
}
.irs--sharp .irs-handle.state_hover,
.irs--sharp .irs-handle:hover {
  background-color: #000
}
.irs--sharp .irs-handle.state_hover > i:first-child,
.irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: #000
}
.irs--sharp .irs-max,
.irs--sharp .irs-min {
  color: #fff;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 4px;
  opacity: .4;
  background-color: #a804b2;
  border-radius: 2px
}
.irs--sharp .irs-from,
.irs--sharp .irs-single,
.irs--sharp .irs-to {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 4px;
  background-color: #a804b2;
  color: #fff;
  border-radius: 2px
}
.irs--sharp .irs-from:before,
.irs--sharp .irs-single:before,
.irs--sharp .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #a804b2
}
.irs--sharp .irs-grid {
  height: 25px
}
.irs--sharp .irs-grid-pol {
  background-color: #dedede
}
.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px
}
.irs--round {
  height: 50px
}
.irs--round.irs-with-grid {
  height: 65px
}
.irs--round .irs-line {
  top: 36px;
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px
}
.irs--round .irs-bar {
  top: 36px;
  height: 4px
}
.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px
}
.irs--round .irs-shadow {
  height: 4px;
  bottom: 21px;
  background-color: rgba(222,228,236,.5)
}
.irs--round .irs-handle {
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0,0,255,.3)
}
.irs--round .irs-max,
.irs--round .irs-min {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0,0,0,.1);
  border-radius: 4px
}
.irs--round .irs-from,
.irs--round .irs-single,
.irs--round .irs-to {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  color: #fff;
  border-radius: 4px
}
.irs--round .irs-from:before,
.irs--round .irs-single:before,
.irs--round .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent
}
.irs--round .irs-grid {
  height: 25px
}
.irs--round .irs-grid-pol {
  background-color: #dedede
}
.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px
}
.irs--square {
  height: 50px
}
.irs--square.irs-with-grid {
  height: 60px
}
.irs--square .irs-line {
  top: 31px;
  height: 4px;
  background-color: #dedede
}
.irs--square .irs-bar {
  top: 31px;
  height: 4px;
  background-color: #000
}
.irs--square .irs-shadow {
  height: 2px;
  bottom: 21px;
  background-color: #dedede
}
.irs--square .irs-handle {
  top: 25px;
  width: 16px;
  height: 16px;
  border: 3px solid #000;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}
.irs--square .irs-handle.state_hover,
.irs--square .irs-handle:hover {
  background-color: #f0f6ff
}
.irs--square .irs-max,
.irs--square .irs-min {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0,0,0,.1)
}
.irs--square .irs-from,
.irs--square .irs-single,
.irs--square .irs-to {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #000;
  color: #fff
}
.irs--square .irs-grid {
  height: 25px
}
.irs--square .irs-grid-pol {
  background-color: #dedede
}
.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px
}
[data-aos][data-aos][data-aos-duration='50'],
body[data-aos-duration='50'] [data-aos] {
  transition-duration: 50ms
}
[data-aos][data-aos][data-aos-delay='50'],
body[data-aos-delay='50'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='50'].aos-animate,
body[data-aos-delay='50'] [data-aos].aos-animate {
  transition-delay: 50ms
}
[data-aos][data-aos][data-aos-duration='100'],
body[data-aos-duration='100'] [data-aos] {
  transition-duration: .1s
}
[data-aos][data-aos][data-aos-delay='100'],
body[data-aos-delay='100'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='100'].aos-animate,
body[data-aos-delay='100'] [data-aos].aos-animate {
  transition-delay: .1s
}
[data-aos][data-aos][data-aos-duration='150'],
body[data-aos-duration='150'] [data-aos] {
  transition-duration: 150ms
}
[data-aos][data-aos][data-aos-delay='150'],
body[data-aos-delay='150'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='150'].aos-animate,
body[data-aos-delay='150'] [data-aos].aos-animate {
  transition-delay: 150ms
}
[data-aos][data-aos][data-aos-duration='200'],
body[data-aos-duration='200'] [data-aos] {
  transition-duration: .2s
}
[data-aos][data-aos][data-aos-delay='200'],
body[data-aos-delay='200'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='200'].aos-animate,
body[data-aos-delay='200'] [data-aos].aos-animate {
  transition-delay: .2s
}
[data-aos][data-aos][data-aos-duration='250'],
body[data-aos-duration='250'] [data-aos] {
  transition-duration: 250ms
}
[data-aos][data-aos][data-aos-delay='250'],
body[data-aos-delay='250'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='250'].aos-animate,
body[data-aos-delay='250'] [data-aos].aos-animate {
  transition-delay: 250ms
}
[data-aos][data-aos][data-aos-duration='300'],
body[data-aos-duration='300'] [data-aos] {
  transition-duration: .3s
}
[data-aos][data-aos][data-aos-delay='300'],
body[data-aos-delay='300'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='300'].aos-animate,
body[data-aos-delay='300'] [data-aos].aos-animate {
  transition-delay: .3s
}
[data-aos][data-aos][data-aos-duration='350'],
body[data-aos-duration='350'] [data-aos] {
  transition-duration: 350ms
}
[data-aos][data-aos][data-aos-delay='350'],
body[data-aos-delay='350'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='350'].aos-animate,
body[data-aos-delay='350'] [data-aos].aos-animate {
  transition-delay: 350ms
}
[data-aos][data-aos][data-aos-duration='400'],
body[data-aos-duration='400'] [data-aos] {
  transition-duration: .4s
}
[data-aos][data-aos][data-aos-delay='400'],
body[data-aos-delay='400'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='400'].aos-animate,
body[data-aos-delay='400'] [data-aos].aos-animate {
  transition-delay: .4s
}
[data-aos][data-aos][data-aos-duration='450'],
body[data-aos-duration='450'] [data-aos] {
  transition-duration: 450ms
}
[data-aos][data-aos][data-aos-delay='450'],
body[data-aos-delay='450'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='450'].aos-animate,
body[data-aos-delay='450'] [data-aos].aos-animate {
  transition-delay: 450ms
}
[data-aos][data-aos][data-aos-duration='500'],
body[data-aos-duration='500'] [data-aos] {
  transition-duration: .5s
}
[data-aos][data-aos][data-aos-delay='500'],
body[data-aos-delay='500'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='500'].aos-animate,
body[data-aos-delay='500'] [data-aos].aos-animate {
  transition-delay: .5s
}
[data-aos][data-aos][data-aos-duration='550'],
body[data-aos-duration='550'] [data-aos] {
  transition-duration: 550ms
}
[data-aos][data-aos][data-aos-delay='550'],
body[data-aos-delay='550'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='550'].aos-animate,
body[data-aos-delay='550'] [data-aos].aos-animate {
  transition-delay: 550ms
}
[data-aos][data-aos][data-aos-duration='600'],
body[data-aos-duration='600'] [data-aos] {
  transition-duration: .6s
}
[data-aos][data-aos][data-aos-delay='600'],
body[data-aos-delay='600'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='600'].aos-animate,
body[data-aos-delay='600'] [data-aos].aos-animate {
  transition-delay: .6s
}
[data-aos][data-aos][data-aos-duration='650'],
body[data-aos-duration='650'] [data-aos] {
  transition-duration: 650ms
}
[data-aos][data-aos][data-aos-delay='650'],
body[data-aos-delay='650'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='650'].aos-animate,
body[data-aos-delay='650'] [data-aos].aos-animate {
  transition-delay: 650ms
}
[data-aos][data-aos][data-aos-duration='700'],
body[data-aos-duration='700'] [data-aos] {
  transition-duration: .7s
}
[data-aos][data-aos][data-aos-delay='700'],
body[data-aos-delay='700'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='700'].aos-animate,
body[data-aos-delay='700'] [data-aos].aos-animate {
  transition-delay: .7s
}
[data-aos][data-aos][data-aos-duration='750'],
body[data-aos-duration='750'] [data-aos] {
  transition-duration: 750ms
}
[data-aos][data-aos][data-aos-delay='750'],
body[data-aos-delay='750'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='750'].aos-animate,
body[data-aos-delay='750'] [data-aos].aos-animate {
  transition-delay: 750ms
}
[data-aos][data-aos][data-aos-duration='800'],
body[data-aos-duration='800'] [data-aos] {
  transition-duration: .8s
}
[data-aos][data-aos][data-aos-delay='800'],
body[data-aos-delay='800'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='800'].aos-animate,
body[data-aos-delay='800'] [data-aos].aos-animate {
  transition-delay: .8s
}
[data-aos][data-aos][data-aos-duration='850'],
body[data-aos-duration='850'] [data-aos] {
  transition-duration: 850ms
}
[data-aos][data-aos][data-aos-delay='850'],
body[data-aos-delay='850'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='850'].aos-animate,
body[data-aos-delay='850'] [data-aos].aos-animate {
  transition-delay: 850ms
}
[data-aos][data-aos][data-aos-duration='900'],
body[data-aos-duration='900'] [data-aos] {
  transition-duration: .9s
}
[data-aos][data-aos][data-aos-delay='900'],
body[data-aos-delay='900'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='900'].aos-animate,
body[data-aos-delay='900'] [data-aos].aos-animate {
  transition-delay: .9s
}
[data-aos][data-aos][data-aos-duration='950'],
body[data-aos-duration='950'] [data-aos] {
  transition-duration: 950ms
}
[data-aos][data-aos][data-aos-delay='950'],
body[data-aos-delay='950'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='950'].aos-animate,
body[data-aos-delay='950'] [data-aos].aos-animate {
  transition-delay: 950ms
}
[data-aos][data-aos][data-aos-duration='1000'],
body[data-aos-duration='1000'] [data-aos] {
  transition-duration: 1s
}
[data-aos][data-aos][data-aos-delay='1000'],
body[data-aos-delay='1000'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1000'].aos-animate,
body[data-aos-delay='1000'] [data-aos].aos-animate {
  transition-delay: 1s
}
[data-aos][data-aos][data-aos-duration='1050'],
body[data-aos-duration='1050'] [data-aos] {
  transition-duration: 1.05s
}
[data-aos][data-aos][data-aos-delay='1050'],
body[data-aos-delay='1050'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1050'].aos-animate,
body[data-aos-delay='1050'] [data-aos].aos-animate {
  transition-delay: 1.05s
}
[data-aos][data-aos][data-aos-duration='1100'],
body[data-aos-duration='1100'] [data-aos] {
  transition-duration: 1.1s
}
[data-aos][data-aos][data-aos-delay='1100'],
body[data-aos-delay='1100'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1100'].aos-animate,
body[data-aos-delay='1100'] [data-aos].aos-animate {
  transition-delay: 1.1s
}
[data-aos][data-aos][data-aos-duration='1150'],
body[data-aos-duration='1150'] [data-aos] {
  transition-duration: 1.15s
}
[data-aos][data-aos][data-aos-delay='1150'],
body[data-aos-delay='1150'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1150'].aos-animate,
body[data-aos-delay='1150'] [data-aos].aos-animate {
  transition-delay: 1.15s
}
[data-aos][data-aos][data-aos-duration='1200'],
body[data-aos-duration='1200'] [data-aos] {
  transition-duration: 1.2s
}
[data-aos][data-aos][data-aos-delay='1200'],
body[data-aos-delay='1200'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1200'].aos-animate,
body[data-aos-delay='1200'] [data-aos].aos-animate {
  transition-delay: 1.2s
}
[data-aos][data-aos][data-aos-duration='1250'],
body[data-aos-duration='1250'] [data-aos] {
  transition-duration: 1.25s
}
[data-aos][data-aos][data-aos-delay='1250'],
body[data-aos-delay='1250'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1250'].aos-animate,
body[data-aos-delay='1250'] [data-aos].aos-animate {
  transition-delay: 1.25s
}
[data-aos][data-aos][data-aos-duration='1300'],
body[data-aos-duration='1300'] [data-aos] {
  transition-duration: 1.3s
}
[data-aos][data-aos][data-aos-delay='1300'],
body[data-aos-delay='1300'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1300'].aos-animate,
body[data-aos-delay='1300'] [data-aos].aos-animate {
  transition-delay: 1.3s
}
[data-aos][data-aos][data-aos-duration='1350'],
body[data-aos-duration='1350'] [data-aos] {
  transition-duration: 1.35s
}
[data-aos][data-aos][data-aos-delay='1350'],
body[data-aos-delay='1350'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1350'].aos-animate,
body[data-aos-delay='1350'] [data-aos].aos-animate {
  transition-delay: 1.35s
}
[data-aos][data-aos][data-aos-duration='1400'],
body[data-aos-duration='1400'] [data-aos] {
  transition-duration: 1.4s
}
[data-aos][data-aos][data-aos-delay='1400'],
body[data-aos-delay='1400'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1400'].aos-animate,
body[data-aos-delay='1400'] [data-aos].aos-animate {
  transition-delay: 1.4s
}
[data-aos][data-aos][data-aos-duration='1450'],
body[data-aos-duration='1450'] [data-aos] {
  transition-duration: 1.45s
}
[data-aos][data-aos][data-aos-delay='1450'],
body[data-aos-delay='1450'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1450'].aos-animate,
body[data-aos-delay='1450'] [data-aos].aos-animate {
  transition-delay: 1.45s
}
[data-aos][data-aos][data-aos-duration='1500'],
body[data-aos-duration='1500'] [data-aos] {
  transition-duration: 1.5s
}
[data-aos][data-aos][data-aos-delay='1500'],
body[data-aos-delay='1500'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1500'].aos-animate,
body[data-aos-delay='1500'] [data-aos].aos-animate {
  transition-delay: 1.5s
}
[data-aos][data-aos][data-aos-duration='1550'],
body[data-aos-duration='1550'] [data-aos] {
  transition-duration: 1.55s
}
[data-aos][data-aos][data-aos-delay='1550'],
body[data-aos-delay='1550'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1550'].aos-animate,
body[data-aos-delay='1550'] [data-aos].aos-animate {
  transition-delay: 1.55s
}
[data-aos][data-aos][data-aos-duration='1600'],
body[data-aos-duration='1600'] [data-aos] {
  transition-duration: 1.6s
}
[data-aos][data-aos][data-aos-delay='1600'],
body[data-aos-delay='1600'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1600'].aos-animate,
body[data-aos-delay='1600'] [data-aos].aos-animate {
  transition-delay: 1.6s
}
[data-aos][data-aos][data-aos-duration='1650'],
body[data-aos-duration='1650'] [data-aos] {
  transition-duration: 1.65s
}
[data-aos][data-aos][data-aos-delay='1650'],
body[data-aos-delay='1650'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1650'].aos-animate,
body[data-aos-delay='1650'] [data-aos].aos-animate {
  transition-delay: 1.65s
}
[data-aos][data-aos][data-aos-duration='1700'],
body[data-aos-duration='1700'] [data-aos] {
  transition-duration: 1.7s
}
[data-aos][data-aos][data-aos-delay='1700'],
body[data-aos-delay='1700'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1700'].aos-animate,
body[data-aos-delay='1700'] [data-aos].aos-animate {
  transition-delay: 1.7s
}
[data-aos][data-aos][data-aos-duration='1750'],
body[data-aos-duration='1750'] [data-aos] {
  transition-duration: 1.75s
}
[data-aos][data-aos][data-aos-delay='1750'],
body[data-aos-delay='1750'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1750'].aos-animate,
body[data-aos-delay='1750'] [data-aos].aos-animate {
  transition-delay: 1.75s
}
[data-aos][data-aos][data-aos-duration='1800'],
body[data-aos-duration='1800'] [data-aos] {
  transition-duration: 1.8s
}
[data-aos][data-aos][data-aos-delay='1800'],
body[data-aos-delay='1800'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1800'].aos-animate,
body[data-aos-delay='1800'] [data-aos].aos-animate {
  transition-delay: 1.8s
}
[data-aos][data-aos][data-aos-duration='1850'],
body[data-aos-duration='1850'] [data-aos] {
  transition-duration: 1.85s
}
[data-aos][data-aos][data-aos-delay='1850'],
body[data-aos-delay='1850'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1850'].aos-animate,
body[data-aos-delay='1850'] [data-aos].aos-animate {
  transition-delay: 1.85s
}
[data-aos][data-aos][data-aos-duration='1900'],
body[data-aos-duration='1900'] [data-aos] {
  transition-duration: 1.9s
}
[data-aos][data-aos][data-aos-delay='1900'],
body[data-aos-delay='1900'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1900'].aos-animate,
body[data-aos-delay='1900'] [data-aos].aos-animate {
  transition-delay: 1.9s
}
[data-aos][data-aos][data-aos-duration='1950'],
body[data-aos-duration='1950'] [data-aos] {
  transition-duration: 1.95s
}
[data-aos][data-aos][data-aos-delay='1950'],
body[data-aos-delay='1950'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1950'].aos-animate,
body[data-aos-delay='1950'] [data-aos].aos-animate {
  transition-delay: 1.95s
}
[data-aos][data-aos][data-aos-duration='2000'],
body[data-aos-duration='2000'] [data-aos] {
  transition-duration: 2s
}
[data-aos][data-aos][data-aos-delay='2000'],
body[data-aos-delay='2000'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2000'].aos-animate,
body[data-aos-delay='2000'] [data-aos].aos-animate {
  transition-delay: 2s
}
[data-aos][data-aos][data-aos-duration='2050'],
body[data-aos-duration='2050'] [data-aos] {
  transition-duration: 2.05s
}
[data-aos][data-aos][data-aos-delay='2050'],
body[data-aos-delay='2050'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2050'].aos-animate,
body[data-aos-delay='2050'] [data-aos].aos-animate {
  transition-delay: 2.05s
}
[data-aos][data-aos][data-aos-duration='2100'],
body[data-aos-duration='2100'] [data-aos] {
  transition-duration: 2.1s
}
[data-aos][data-aos][data-aos-delay='2100'],
body[data-aos-delay='2100'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2100'].aos-animate,
body[data-aos-delay='2100'] [data-aos].aos-animate {
  transition-delay: 2.1s
}
[data-aos][data-aos][data-aos-duration='2150'],
body[data-aos-duration='2150'] [data-aos] {
  transition-duration: 2.15s
}
[data-aos][data-aos][data-aos-delay='2150'],
body[data-aos-delay='2150'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2150'].aos-animate,
body[data-aos-delay='2150'] [data-aos].aos-animate {
  transition-delay: 2.15s
}
[data-aos][data-aos][data-aos-duration='2200'],
body[data-aos-duration='2200'] [data-aos] {
  transition-duration: 2.2s
}
[data-aos][data-aos][data-aos-delay='2200'],
body[data-aos-delay='2200'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2200'].aos-animate,
body[data-aos-delay='2200'] [data-aos].aos-animate {
  transition-delay: 2.2s
}
[data-aos][data-aos][data-aos-duration='2250'],
body[data-aos-duration='2250'] [data-aos] {
  transition-duration: 2.25s
}
[data-aos][data-aos][data-aos-delay='2250'],
body[data-aos-delay='2250'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2250'].aos-animate,
body[data-aos-delay='2250'] [data-aos].aos-animate {
  transition-delay: 2.25s
}
[data-aos][data-aos][data-aos-duration='2300'],
body[data-aos-duration='2300'] [data-aos] {
  transition-duration: 2.3s
}
[data-aos][data-aos][data-aos-delay='2300'],
body[data-aos-delay='2300'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2300'].aos-animate,
body[data-aos-delay='2300'] [data-aos].aos-animate {
  transition-delay: 2.3s
}
[data-aos][data-aos][data-aos-duration='2350'],
body[data-aos-duration='2350'] [data-aos] {
  transition-duration: 2.35s
}
[data-aos][data-aos][data-aos-delay='2350'],
body[data-aos-delay='2350'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2350'].aos-animate,
body[data-aos-delay='2350'] [data-aos].aos-animate {
  transition-delay: 2.35s
}
[data-aos][data-aos][data-aos-duration='2400'],
body[data-aos-duration='2400'] [data-aos] {
  transition-duration: 2.4s
}
[data-aos][data-aos][data-aos-delay='2400'],
body[data-aos-delay='2400'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2400'].aos-animate,
body[data-aos-delay='2400'] [data-aos].aos-animate {
  transition-delay: 2.4s
}
[data-aos][data-aos][data-aos-duration='2450'],
body[data-aos-duration='2450'] [data-aos] {
  transition-duration: 2.45s
}
[data-aos][data-aos][data-aos-delay='2450'],
body[data-aos-delay='2450'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2450'].aos-animate,
body[data-aos-delay='2450'] [data-aos].aos-animate {
  transition-delay: 2.45s
}
[data-aos][data-aos][data-aos-duration='2500'],
body[data-aos-duration='2500'] [data-aos] {
  transition-duration: 2.5s
}
[data-aos][data-aos][data-aos-delay='2500'],
body[data-aos-delay='2500'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2500'].aos-animate,
body[data-aos-delay='2500'] [data-aos].aos-animate {
  transition-delay: 2.5s
}
[data-aos][data-aos][data-aos-duration='2550'],
body[data-aos-duration='2550'] [data-aos] {
  transition-duration: 2.55s
}
[data-aos][data-aos][data-aos-delay='2550'],
body[data-aos-delay='2550'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2550'].aos-animate,
body[data-aos-delay='2550'] [data-aos].aos-animate {
  transition-delay: 2.55s
}
[data-aos][data-aos][data-aos-duration='2600'],
body[data-aos-duration='2600'] [data-aos] {
  transition-duration: 2.6s
}
[data-aos][data-aos][data-aos-delay='2600'],
body[data-aos-delay='2600'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2600'].aos-animate,
body[data-aos-delay='2600'] [data-aos].aos-animate {
  transition-delay: 2.6s
}
[data-aos][data-aos][data-aos-duration='2650'],
body[data-aos-duration='2650'] [data-aos] {
  transition-duration: 2.65s
}
[data-aos][data-aos][data-aos-delay='2650'],
body[data-aos-delay='2650'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2650'].aos-animate,
body[data-aos-delay='2650'] [data-aos].aos-animate {
  transition-delay: 2.65s
}
[data-aos][data-aos][data-aos-duration='2700'],
body[data-aos-duration='2700'] [data-aos] {
  transition-duration: 2.7s
}
[data-aos][data-aos][data-aos-delay='2700'],
body[data-aos-delay='2700'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2700'].aos-animate,
body[data-aos-delay='2700'] [data-aos].aos-animate {
  transition-delay: 2.7s
}
[data-aos][data-aos][data-aos-duration='2750'],
body[data-aos-duration='2750'] [data-aos] {
  transition-duration: 2.75s
}
[data-aos][data-aos][data-aos-delay='2750'],
body[data-aos-delay='2750'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2750'].aos-animate,
body[data-aos-delay='2750'] [data-aos].aos-animate {
  transition-delay: 2.75s
}
[data-aos][data-aos][data-aos-duration='2800'],
body[data-aos-duration='2800'] [data-aos] {
  transition-duration: 2.8s
}
[data-aos][data-aos][data-aos-delay='2800'],
body[data-aos-delay='2800'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2800'].aos-animate,
body[data-aos-delay='2800'] [data-aos].aos-animate {
  transition-delay: 2.8s
}
[data-aos][data-aos][data-aos-duration='2850'],
body[data-aos-duration='2850'] [data-aos] {
  transition-duration: 2.85s
}
[data-aos][data-aos][data-aos-delay='2850'],
body[data-aos-delay='2850'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2850'].aos-animate,
body[data-aos-delay='2850'] [data-aos].aos-animate {
  transition-delay: 2.85s
}
[data-aos][data-aos][data-aos-duration='2900'],
body[data-aos-duration='2900'] [data-aos] {
  transition-duration: 2.9s
}
[data-aos][data-aos][data-aos-delay='2900'],
body[data-aos-delay='2900'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2900'].aos-animate,
body[data-aos-delay='2900'] [data-aos].aos-animate {
  transition-delay: 2.9s
}
[data-aos][data-aos][data-aos-duration='2950'],
body[data-aos-duration='2950'] [data-aos] {
  transition-duration: 2.95s
}
[data-aos][data-aos][data-aos-delay='2950'],
body[data-aos-delay='2950'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2950'].aos-animate,
body[data-aos-delay='2950'] [data-aos].aos-animate {
  transition-delay: 2.95s
}
[data-aos][data-aos][data-aos-duration='3000'],
body[data-aos-duration='3000'] [data-aos] {
  transition-duration: 3s
}
[data-aos][data-aos][data-aos-delay='3000'],
body[data-aos-delay='3000'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='3000'].aos-animate,
body[data-aos-delay='3000'] [data-aos].aos-animate {
  transition-delay: 3s
}
[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(.25,.25,.75,.75)
}
[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: cubic-bezier(.25,.1,.25,1)
}
[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: cubic-bezier(.42,0,1,1)
}
[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: cubic-bezier(0,0,.58,1)
}
[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: cubic-bezier(.42,0,.58,1)
}
[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(.6,-.28,.735,.045)
}
[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(.175,.885,.32,1.275)
}
[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(.68,-.55,.265,1.55)
}
[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(.47,0,.745,.715)
}
[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(.39,.575,.565,1)
}
[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(.445,.05,.55,.95)
}
[data-aos][data-aos][data-aos-easing=ease-in-cubic],
[data-aos][data-aos][data-aos-easing=ease-in-quad],
[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-cubic] [data-aos],
body[data-aos-easing=ease-in-quad] [data-aos],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(.55,.085,.68,.53)
}
[data-aos][data-aos][data-aos-easing=ease-out-cubic],
[data-aos][data-aos][data-aos-easing=ease-out-quad],
[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-cubic] [data-aos],
body[data-aos-easing=ease-out-quad] [data-aos],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(.25,.46,.45,.94)
}
[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-cubic] [data-aos],
body[data-aos-easing=ease-in-out-quad] [data-aos],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(.455,.03,.515,.955)
}
[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform
}
[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate3d(0,0,0)
}
[data-aos=fade-up] {
  transform: translate3d(0,100px,0)
}
[data-aos=fade-down] {
  transform: translate3d(0,-100px,0)
}
[data-aos=fade-right] {
  transform: translate3d(-100px,0,0)
}
[data-aos=fade-left] {
  transform: translate3d(100px,0,0)
}
[data-aos=fade-up-right] {
  transform: translate3d(-100px,100px,0)
}
[data-aos=fade-up-left] {
  transform: translate3d(100px,100px,0)
}
[data-aos=fade-down-right] {
  transform: translate3d(-100px,-100px,0)
}
[data-aos=fade-down-left] {
  transform: translate3d(100px,-100px,0)
}
[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate3d(0,0,0) scale(1)
}
[data-aos=zoom-in] {
  transform: scale(.6)
}
[data-aos=zoom-in-up] {
  transform: translate3d(0,100px,0) scale(.6)
}
[data-aos=zoom-in-down] {
  transform: translate3d(0,-100px,0) scale(.6)
}
[data-aos=zoom-in-right] {
  transform: translate3d(-100px,0,0) scale(.6)
}
[data-aos=zoom-in-left] {
  transform: translate3d(100px,0,0) scale(.6)
}
[data-aos=zoom-out] {
  transform: scale(1.2)
}
[data-aos=zoom-out-up] {
  transform: translate3d(0,100px,0) scale(1.2)
}
[data-aos=zoom-out-down] {
  transform: translate3d(0,-100px,0) scale(1.2)
}
[data-aos=zoom-out-right] {
  transform: translate3d(-100px,0,0) scale(1.2)
}
[data-aos=zoom-out-left] {
  transform: translate3d(100px,0,0) scale(1.2)
}
[data-aos^=slide][data-aos^=slide] {
  transition-property: transform
}
[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate3d(0,0,0)
}
[data-aos=slide-up] {
  transform: translate3d(0,100%,0)
}
[data-aos=slide-down] {
  transform: translate3d(0,-100%,0)
}
[data-aos=slide-right] {
  transform: translate3d(-100%,0,0)
}
[data-aos=slide-left] {
  transform: translate3d(100%,0,0)
}
[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform
}
[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg)
}
[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0)
}
[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg)
}
[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0)
}
[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg)
}
[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0)
}
[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg)
}
[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0)
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0
}
.slick-list:focus {
  outline: 0
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  -o-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto
}
.slick-track:after,
.slick-track:before {
  content: "";
  display: table
}
.slick-track:after {
  clear: both
}
.slick-loading .slick-track {
  visibility: hidden
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none
}
[dir=rtl] .slick-slide {
  float: right
}
.slick-slide img {
  display: block
}
.slick-slide.slick-loading img {
  display: none
}
.slick-slide.dragging img {
  pointer-events: none
}
.slick-initialized .slick-slide {
  display: block
}
.slick-loading .slick-slide {
  visibility: hidden
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}
.slick-arrow.slick-hidden {
  display: none
}
.tab-content__item {
  width: 100%;
  height: 100%;
  position: absolute
}
.section--bg-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25)
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}
.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6
}
.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem
}
.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}
.align-baseline {
  vertical-align: baseline!important
}
.align-top {
  vertical-align: top!important
}
.align-middle {
  vertical-align: middle!important
}
.align-bottom {
  vertical-align: bottom!important
}
.align-text-bottom {
  vertical-align: text-bottom!important
}
.align-text-top {
  vertical-align: text-top!important
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden
}
.embed-responsive::before {
  display: block;
  content: ""
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}
.embed-responsive-21by9::before {
  padding-top: 42.85714%
}
.embed-responsive-16by9::before {
  padding-top: 56.25%
}
.embed-responsive-4by3::before {
  padding-top: 75%
}
.embed-responsive-1by1::before {
  padding-top: 100%
}
.position-static {
  position: static!important
}
.position-relative {
  position: relative!important
}
.position-absolute {
  position: absolute!important
}
.position-fixed {
  position: fixed!important
}
.position-sticky {
  position: sticky!important
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}
@supports (position:sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}
.w-25 {
  width: 25%!important
}
.w-50 {
  width: 50%!important
}
.w-75 {
  width: 75%!important
}
.w-100 {
  width: 100%!important
}
.w-auto {
  width: auto!important
}
.h-25 {
  height: 25%!important
}
.h-50 {
  height: 50%!important
}
.h-75 {
  height: 75%!important
}
.h-100 {
  height: 100%!important
}
.h-auto {
  height: auto!important
}
.mw-100 {
  max-width: 100%!important
}
.mh-100 {
  max-height: 100%!important
}
.m-0 {
  margin: 0!important
}
.mt-0,
.my-0 {
  margin-top: 0!important
}
.mr-0,
.mx-0 {
  margin-right: 0!important
}
.mb-0,
.my-0 {
  margin-bottom: 0!important
}
.ml-0,
.mx-0 {
  margin-left: 0!important
}
.m-1 {
  margin: .5rem!important
}
.mt-1,
.my-1 {
  margin-top: .5rem!important
}
.mr-1,
.mx-1 {
  margin-right: .5rem!important
}
.mb-1,
.my-1 {
  margin-bottom: .5rem!important
}
.ml-1,
.mx-1 {
  margin-left: .5rem!important
}
.m-2 {
  margin: 1rem!important
}
.mt-2,
.my-2 {
  margin-top: 1rem!important
}
.mr-2,
.mx-2 {
  margin-right: 1rem!important
}
.mb-2,
.my-2 {
  margin-bottom: 1rem!important
}
.ml-2,
.mx-2 {
  margin-left: 1rem!important
}
.m-3 {
  margin: 1.5rem!important
}
.mt-3,
.my-3 {
  margin-top: 1.5rem!important
}
.mr-3,
.mx-3 {
  margin-right: 1.5rem!important
}
.mb-3,
.my-3 {
  margin-bottom: 1.5rem!important
}
.ml-3,
.mx-3 {
  margin-left: 1.5rem!important
}
.m-4 {
  margin: 2rem!important
}
.mt-4,
.my-4 {
  margin-top: 2rem!important
}
.mr-4,
.mx-4 {
  margin-right: 2rem!important
}
.mb-4,
.my-4 {
  margin-bottom: 2rem!important
}
.ml-4,
.mx-4 {
  margin-left: 2rem!important
}
.m-5 {
  margin: 2.5rem!important
}
.mt-5,
.my-5 {
  margin-top: 2.5rem!important
}
.mr-5,
.mx-5 {
  margin-right: 2.5rem!important
}
.mb-5,
.my-5 {
  margin-bottom: 2.5rem!important
}
.ml-5,
.mx-5 {
  margin-left: 2.5rem!important
}
.m-6 {
  margin: 3rem!important
}
.mt-6,
.my-6 {
  margin-top: 3rem!important
}
.mr-6,
.mx-6 {
  margin-right: 3rem!important
}
.mb-6,
.my-6 {
  margin-bottom: 3rem!important
}
.ml-6,
.mx-6 {
  margin-left: 3rem!important
}
.m-7 {
  margin: 3.5rem!important
}
.mt-7,
.my-7 {
  margin-top: 3.5rem!important
}
.mr-7,
.mx-7 {
  margin-right: 3.5rem!important
}
.mb-7,
.my-7 {
  margin-bottom: 3.5rem!important
}
.ml-7,
.mx-7 {
  margin-left: 3.5rem!important
}
.m-8 {
  margin: 4rem!important
}
.mt-8,
.my-8 {
  margin-top: 4rem!important
}
.mr-8,
.mx-8 {
  margin-right: 4rem!important
}
.mb-8,
.my-8 {
  margin-bottom: 4rem!important
}
.ml-8,
.mx-8 {
  margin-left: 4rem!important
}
.m-9 {
  margin: 4.5rem!important
}
.mt-9,
.my-9 {
  margin-top: 4.5rem!important
}
.mr-9,
.mx-9 {
  margin-right: 4.5rem!important
}
.mb-9,
.my-9 {
  margin-bottom: 4.5rem!important
}
.ml-9,
.mx-9 {
  margin-left: 4.5rem!important
}
.m-10 {
  margin: 5rem!important
}
.mt-10,
.my-10 {
  margin-top: 5rem!important
}
.mr-10,
.mx-10 {
  margin-right: 5rem!important
}
.mb-10,
.my-10 {
  margin-bottom: 5rem!important
}
.ml-10,
.mx-10 {
  margin-left: 5rem!important
}
.m-11 {
  margin: 5.5rem!important
}
.mt-11,
.my-11 {
  margin-top: 5.5rem!important
}
.mr-11,
.mx-11 {
  margin-right: 5.5rem!important
}
.mb-11,
.my-11 {
  margin-bottom: 5.5rem!important
}
.ml-11,
.mx-11 {
  margin-left: 5.5rem!important
}
.m-12 {
  margin: 6rem!important
}
.mt-12,
.my-12 {
  margin-top: 6rem!important
}
.mr-12,
.mx-12 {
  margin-right: 6rem!important
}
.mb-12,
.my-12 {
  margin-bottom: 6rem!important
}
.ml-12,
.mx-12 {
  margin-left: 6rem!important
}
.p-0 {
  padding: 0!important
}
.pt-0,
.py-0 {
  padding-top: 0!important
}
.pr-0,
.px-0 {
  padding-right: 0!important
}
.pb-0,
.py-0 {
  padding-bottom: 0!important
}
.pl-0,
.px-0 {
  padding-left: 0!important
}
.p-1 {
  padding: .5rem!important
}
.pt-1,
.py-1 {
  padding-top: .5rem!important
}
.pr-1,
.px-1 {
  padding-right: .5rem!important
}
.pb-1,
.py-1 {
  padding-bottom: .5rem!important
}
.pl-1,
.px-1 {
  padding-left: .5rem!important
}
.p-2 {
  padding: 1rem!important
}
.pt-2,
.py-2 {
  padding-top: 1rem!important
}
.pr-2,
.px-2 {
  padding-right: 1rem!important
}
.pb-2,
.py-2 {
  padding-bottom: 1rem!important
}
.pl-2,
.px-2 {
  padding-left: 1rem!important
}
.p-3 {
  padding: 1.5rem!important
}
.pt-3,
.py-3 {
  padding-top: 1.5rem!important
}
.pr-3,
.px-3 {
  padding-right: 1.5rem!important
}
.pb-3,
.py-3 {
  padding-bottom: 1.5rem!important
}
.pl-3,
.px-3 {
  padding-left: 1.5rem!important
}
.p-4 {
  padding: 2rem!important
}
.pt-4,
.py-4 {
  padding-top: 2rem!important
}
.pr-4,
.px-4 {
  padding-right: 2rem!important
}
.pb-4,
.py-4 {
  padding-bottom: 2rem!important
}
.pl-4,
.px-4 {
  padding-left: 2rem!important
}
.p-5 {
  padding: 2.5rem!important
}
.pt-5,
.py-5 {
  padding-top: 2.5rem!important
}
.pr-5,
.px-5 {
  padding-right: 2.5rem!important
}
.pb-5,
.py-5 {
  padding-bottom: 2.5rem!important
}
.pl-5,
.px-5 {
  padding-left: 2.5rem!important
}
.p-6 {
  padding: 3rem!important
}
.pt-6,
.py-6 {
  padding-top: 3rem!important
}
.pr-6,
.px-6 {
  padding-right: 3rem!important
}
.pb-6,
.py-6 {
  padding-bottom: 3rem!important
}
.pl-6,
.px-6 {
  padding-left: 3rem!important
}
.p-7 {
  padding: 3.5rem!important
}
.pt-7,
.py-7 {
  padding-top: 3.5rem!important
}
.pr-7,
.px-7 {
  padding-right: 3.5rem!important
}
.pb-7,
.py-7 {
  padding-bottom: 3.5rem!important
}
.pl-7,
.px-7 {
  padding-left: 3.5rem!important
}
.p-8 {
  padding: 4rem!important
}
.pt-8,
.py-8 {
  padding-top: 4rem!important
}
.pr-8,
.px-8 {
  padding-right: 4rem!important
}
.pb-8,
.py-8 {
  padding-bottom: 4rem!important
}
.pl-8,
.px-8 {
  padding-left: 4rem!important
}
.p-9 {
  padding: 4.5rem!important
}
.pt-9,
.py-9 {
  padding-top: 4.5rem!important
}
.pr-9,
.px-9 {
  padding-right: 4.5rem!important
}
.pb-9,
.py-9 {
  padding-bottom: 4.5rem!important
}
.pl-9,
.px-9 {
  padding-left: 4.5rem!important
}
.p-10 {
  padding: 5rem!important
}
.pt-10,
.py-10 {
  padding-top: 5rem!important
}
.pr-10,
.px-10 {
  padding-right: 5rem!important
}
.pb-10,
.py-10 {
  padding-bottom: 5rem!important
}
.pl-10,
.px-10 {
  padding-left: 5rem!important
}
.p-11 {
  padding: 5.5rem!important
}
.pt-11,
.py-11 {
  padding-top: 5.5rem!important
}
.pr-11,
.px-11 {
  padding-right: 5.5rem!important
}
.pb-11,
.py-11 {
  padding-bottom: 5.5rem!important
}
.pl-11,
.px-11 {
  padding-left: 5.5rem!important
}
.p-12 {
  padding: 6rem!important
}
.pt-12,
.py-12 {
  padding-top: 6rem!important
}
.pr-12,
.px-12 {
  padding-right: 6rem!important
}
.pb-12,
.py-12 {
  padding-bottom: 6rem!important
}
.pl-12,
.px-12 {
  padding-left: 6rem!important
}
.m-auto {
  margin: auto!important
}
.mt-auto,
.my-auto {
  margin-top: auto!important
}
.mr-auto,
.mx-auto {
  margin-right: auto!important
}
.mb-auto,
.my-auto {
  margin-bottom: auto!important
}
.ml-auto,
.mx-auto {
  margin-left: auto!important
}
@media (min-width:576px) {
  .m-sm-0 {
    margin: 0!important
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0!important
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0!important
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0!important
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0!important
  }
  .m-sm-1 {
    margin: .5rem!important
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: .5rem!important
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .5rem!important
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .5rem!important
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .5rem!important
  }
  .m-sm-2 {
    margin: 1rem!important
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem!important
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem!important
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem!important
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem!important
  }
  .m-sm-3 {
    margin: 1.5rem!important
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem!important
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem!important
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem!important
  }
  .m-sm-4 {
    margin: 2rem!important
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem!important
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem!important
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem!important
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem!important
  }
  .m-sm-5 {
    margin: 2.5rem!important
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.5rem!important
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.5rem!important
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.5rem!important
  }
  .m-sm-6 {
    margin: 3rem!important
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem!important
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem!important
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem!important
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem!important
  }
  .m-sm-7 {
    margin: 3.5rem!important
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.5rem!important
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.5rem!important
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.5rem!important
  }
  .m-sm-8 {
    margin: 4rem!important
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4rem!important
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 4rem!important
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4rem!important
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 4rem!important
  }
  .m-sm-9 {
    margin: 4.5rem!important
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.5rem!important
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.5rem!important
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.5rem!important
  }
  .m-sm-10 {
    margin: 5rem!important
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem!important
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem!important
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem!important
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem!important
  }
  .m-sm-11 {
    margin: 5.5rem!important
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5.5rem!important
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5.5rem!important
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5.5rem!important
  }
  .m-sm-12 {
    margin: 6rem!important
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 6rem!important
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 6rem!important
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 6rem!important
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 6rem!important
  }
  .p-sm-0 {
    padding: 0!important
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0!important
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0!important
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0!important
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0!important
  }
  .p-sm-1 {
    padding: .5rem!important
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: .5rem!important
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: .5rem!important
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .5rem!important
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: .5rem!important
  }
  .p-sm-2 {
    padding: 1rem!important
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem!important
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem!important
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem!important
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem!important
  }
  .p-sm-3 {
    padding: 1.5rem!important
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem!important
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem!important
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem!important
  }
  .p-sm-4 {
    padding: 2rem!important
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem!important
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem!important
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem!important
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem!important
  }
  .p-sm-5 {
    padding: 2.5rem!important
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.5rem!important
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.5rem!important
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.5rem!important
  }
  .p-sm-6 {
    padding: 3rem!important
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem!important
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem!important
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem!important
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem!important
  }
  .p-sm-7 {
    padding: 3.5rem!important
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.5rem!important
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.5rem!important
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.5rem!important
  }
  .p-sm-8 {
    padding: 4rem!important
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4rem!important
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 4rem!important
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4rem!important
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 4rem!important
  }
  .p-sm-9 {
    padding: 4.5rem!important
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.5rem!important
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.5rem!important
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.5rem!important
  }
  .p-sm-10 {
    padding: 5rem!important
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem!important
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem!important
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem!important
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem!important
  }
  .p-sm-11 {
    padding: 5.5rem!important
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5.5rem!important
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5.5rem!important
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5.5rem!important
  }
  .p-sm-12 {
    padding: 6rem!important
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 6rem!important
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 6rem!important
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 6rem!important
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 6rem!important
  }
  .m-sm-auto {
    margin: auto!important
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto!important
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto!important
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto!important
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto!important
  }
}
@media (min-width:768px) {
  .m-md-0 {
    margin: 0!important
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0!important
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0!important
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0!important
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0!important
  }
  .m-md-1 {
    margin: .5rem!important
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: .5rem!important
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: .5rem!important
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: .5rem!important
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: .5rem!important
  }
  .m-md-2 {
    margin: 1rem!important
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem!important
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem!important
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem!important
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem!important
  }
  .m-md-3 {
    margin: 1.5rem!important
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem!important
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem!important
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem!important
  }
  .m-md-4 {
    margin: 2rem!important
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem!important
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem!important
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem!important
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem!important
  }
  .m-md-5 {
    margin: 2.5rem!important
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.5rem!important
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.5rem!important
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.5rem!important
  }
  .m-md-6 {
    margin: 3rem!important
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem!important
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem!important
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem!important
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem!important
  }
  .m-md-7 {
    margin: 3.5rem!important
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.5rem!important
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.5rem!important
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.5rem!important
  }
  .m-md-8 {
    margin: 4rem!important
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 4rem!important
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 4rem!important
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4rem!important
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 4rem!important
  }
  .m-md-9 {
    margin: 4.5rem!important
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.5rem!important
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.5rem!important
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.5rem!important
  }
  .m-md-10 {
    margin: 5rem!important
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem!important
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem!important
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem!important
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem!important
  }
  .m-md-11 {
    margin: 5.5rem!important
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5.5rem!important
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5.5rem!important
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5.5rem!important
  }
  .m-md-12 {
    margin: 6rem!important
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 6rem!important
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 6rem!important
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 6rem!important
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 6rem!important
  }
  .p-md-0 {
    padding: 0!important
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0!important
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0!important
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0!important
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0!important
  }
  .p-md-1 {
    padding: .5rem!important
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: .5rem!important
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: .5rem!important
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: .5rem!important
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: .5rem!important
  }
  .p-md-2 {
    padding: 1rem!important
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem!important
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem!important
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem!important
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem!important
  }
  .p-md-3 {
    padding: 1.5rem!important
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem!important
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem!important
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem!important
  }
  .p-md-4 {
    padding: 2rem!important
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem!important
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem!important
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem!important
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem!important
  }
  .p-md-5 {
    padding: 2.5rem!important
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.5rem!important
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.5rem!important
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.5rem!important
  }
  .p-md-6 {
    padding: 3rem!important
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem!important
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem!important
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem!important
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem!important
  }
  .p-md-7 {
    padding: 3.5rem!important
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.5rem!important
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.5rem!important
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.5rem!important
  }
  .p-md-8 {
    padding: 4rem!important
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 4rem!important
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 4rem!important
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4rem!important
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 4rem!important
  }
  .p-md-9 {
    padding: 4.5rem!important
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.5rem!important
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.5rem!important
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.5rem!important
  }
  .p-md-10 {
    padding: 5rem!important
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem!important
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem!important
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem!important
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem!important
  }
  .p-md-11 {
    padding: 5.5rem!important
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5.5rem!important
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5.5rem!important
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5.5rem!important
  }
  .p-md-12 {
    padding: 6rem!important
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 6rem!important
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 6rem!important
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6rem!important
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 6rem!important
  }
  .m-md-auto {
    margin: auto!important
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto!important
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto!important
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto!important
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto!important
  }
}
@media (min-width:992px) {
  .m-lg-0 {
    margin: 0!important
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0!important
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0!important
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0!important
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0!important
  }
  .m-lg-1 {
    margin: .5rem!important
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: .5rem!important
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .5rem!important
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .5rem!important
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .5rem!important
  }
  .m-lg-2 {
    margin: 1rem!important
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem!important
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem!important
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem!important
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem!important
  }
  .m-lg-3 {
    margin: 1.5rem!important
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem!important
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem!important
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem!important
  }
  .m-lg-4 {
    margin: 2rem!important
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem!important
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem!important
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem!important
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem!important
  }
  .m-lg-5 {
    margin: 2.5rem!important
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.5rem!important
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.5rem!important
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.5rem!important
  }
  .m-lg-6 {
    margin: 3rem!important
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem!important
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem!important
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem!important
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem!important
  }
  .m-lg-7 {
    margin: 3.5rem!important
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.5rem!important
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.5rem!important
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.5rem!important
  }
  .m-lg-8 {
    margin: 4rem!important
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4rem!important
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 4rem!important
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4rem!important
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 4rem!important
  }
  .m-lg-9 {
    margin: 4.5rem!important
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.5rem!important
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.5rem!important
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.5rem!important
  }
  .m-lg-10 {
    margin: 5rem!important
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem!important
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem!important
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem!important
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem!important
  }
  .m-lg-11 {
    margin: 5.5rem!important
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5.5rem!important
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5.5rem!important
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5.5rem!important
  }
  .m-lg-12 {
    margin: 6rem!important
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 6rem!important
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 6rem!important
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 6rem!important
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 6rem!important
  }
  .p-lg-0 {
    padding: 0!important
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0!important
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0!important
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0!important
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0!important
  }
  .p-lg-1 {
    padding: .5rem!important
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: .5rem!important
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: .5rem!important
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .5rem!important
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: .5rem!important
  }
  .p-lg-2 {
    padding: 1rem!important
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem!important
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem!important
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem!important
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem!important
  }
  .p-lg-3 {
    padding: 1.5rem!important
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem!important
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem!important
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem!important
  }
  .p-lg-4 {
    padding: 2rem!important
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem!important
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem!important
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem!important
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem!important
  }
  .p-lg-5 {
    padding: 2.5rem!important
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.5rem!important
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.5rem!important
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.5rem!important
  }
  .p-lg-6 {
    padding: 3rem!important
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem!important
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem!important
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem!important
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem!important
  }
  .p-lg-7 {
    padding: 3.5rem!important
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.5rem!important
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.5rem!important
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.5rem!important
  }
  .p-lg-8 {
    padding: 4rem!important
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4rem!important
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 4rem!important
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4rem!important
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 4rem!important
  }
  .p-lg-9 {
    padding: 4.5rem!important
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.5rem!important
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.5rem!important
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.5rem!important
  }
  .p-lg-10 {
    padding: 5rem!important
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem!important
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem!important
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem!important
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem!important
  }
  .p-lg-11 {
    padding: 5.5rem!important
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5.5rem!important
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5.5rem!important
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5.5rem!important
  }
  .p-lg-12 {
    padding: 6rem!important
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 6rem!important
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 6rem!important
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 6rem!important
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 6rem!important
  }
  .m-lg-auto {
    margin: auto!important
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto!important
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto!important
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto!important
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto!important
  }
}
.text-monospace {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace
}
.text-justify {
  text-align: justify!important
}
.text-nowrap {
  white-space: nowrap!important
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.text-left {
  text-align: left!important
}
.text-right {
  text-align: right!important
}
.text-center {
  text-align: center!important
}
@media (min-width:576px) {
  .text-sm-left {
    text-align: left!important
  }
  .text-sm-right {
    text-align: right!important
  }
  .text-sm-center {
    text-align: center!important
  }
}
@media (min-width:768px) {
  .text-md-left {
    text-align: left!important
  }
  .text-md-right {
    text-align: right!important
  }
  .text-md-center {
    text-align: center!important
  }
}
@media (min-width:992px) {
  .text-lg-left {
    text-align: left!important
  }
  .text-lg-right {
    text-align: right!important
  }
  .text-lg-center {
    text-align: center!important
  }
}
@media (min-width:1200px) {
  .m-xl-0 {
    margin: 0!important
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0!important
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0!important
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0!important
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0!important
  }
  .m-xl-1 {
    margin: .5rem!important
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: .5rem!important
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .5rem!important
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .5rem!important
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .5rem!important
  }
  .m-xl-2 {
    margin: 1rem!important
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem!important
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem!important
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem!important
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem!important
  }
  .m-xl-3 {
    margin: 1.5rem!important
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem!important
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem!important
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem!important
  }
  .m-xl-4 {
    margin: 2rem!important
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem!important
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem!important
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem!important
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem!important
  }
  .m-xl-5 {
    margin: 2.5rem!important
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.5rem!important
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.5rem!important
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.5rem!important
  }
  .m-xl-6 {
    margin: 3rem!important
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem!important
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem!important
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem!important
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem!important
  }
  .m-xl-7 {
    margin: 3.5rem!important
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.5rem!important
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.5rem!important
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.5rem!important
  }
  .m-xl-8 {
    margin: 4rem!important
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4rem!important
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 4rem!important
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4rem!important
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 4rem!important
  }
  .m-xl-9 {
    margin: 4.5rem!important
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.5rem!important
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.5rem!important
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.5rem!important
  }
  .m-xl-10 {
    margin: 5rem!important
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem!important
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem!important
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem!important
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem!important
  }
  .m-xl-11 {
    margin: 5.5rem!important
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5.5rem!important
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5.5rem!important
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5.5rem!important
  }
  .m-xl-12 {
    margin: 6rem!important
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 6rem!important
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 6rem!important
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 6rem!important
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 6rem!important
  }
  .p-xl-0 {
    padding: 0!important
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0!important
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0!important
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0!important
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0!important
  }
  .p-xl-1 {
    padding: .5rem!important
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: .5rem!important
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: .5rem!important
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .5rem!important
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: .5rem!important
  }
  .p-xl-2 {
    padding: 1rem!important
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem!important
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem!important
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem!important
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem!important
  }
  .p-xl-3 {
    padding: 1.5rem!important
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem!important
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem!important
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem!important
  }
  .p-xl-4 {
    padding: 2rem!important
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem!important
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem!important
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem!important
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem!important
  }
  .p-xl-5 {
    padding: 2.5rem!important
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.5rem!important
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.5rem!important
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.5rem!important
  }
  .p-xl-6 {
    padding: 3rem!important
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem!important
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem!important
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem!important
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem!important
  }
  .p-xl-7 {
    padding: 3.5rem!important
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.5rem!important
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.5rem!important
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.5rem!important
  }
  .p-xl-8 {
    padding: 4rem!important
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4rem!important
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 4rem!important
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4rem!important
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 4rem!important
  }
  .p-xl-9 {
    padding: 4.5rem!important
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.5rem!important
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.5rem!important
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.5rem!important
  }
  .p-xl-10 {
    padding: 5rem!important
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem!important
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem!important
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem!important
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem!important
  }
  .p-xl-11 {
    padding: 5.5rem!important
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5.5rem!important
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5.5rem!important
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5.5rem!important
  }
  .p-xl-12 {
    padding: 6rem!important
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 6rem!important
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 6rem!important
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 6rem!important
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 6rem!important
  }
  .m-xl-auto {
    margin: auto!important
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto!important
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto!important
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto!important
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto!important
  }
  .text-xl-left {
    text-align: left!important
  }
  .text-xl-right {
    text-align: right!important
  }
  .text-xl-center {
    text-align: center!important
  }
}
.text-lowercase {
  text-transform: lowercase!important
}
.text-uppercase {
  text-transform: uppercase!important
}
.text-capitalize {
  text-transform: capitalize!important
}
.font-weight-light {
  font-weight: 300!important
}
.font-weight-normal {
  font-weight: 400!important
}
.font-weight-bold {
  font-weight: 700!important
}
.font-italic {
  font-style: italic!important
}
.text-white {
  color: #fff!important
}
.text-primary {
  color: #007bff!important
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc!important
}
.text-secondary {
  color: #6c757d!important
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62!important
}
.text-success {
  color: #28a745!important
}
a.text-success:focus,
a.text-success:hover {
  color: #1e7e34!important
}
.text-info {
  color: #17a2b8!important
}
a.text-info:focus,
a.text-info:hover {
  color: #117a8b!important
}
.text-warning {
  color: #ffc107!important
}
a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00!important
}
.text-danger {
  color: #dc3545!important
}
a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130!important
}
.text-light {
  color: #f8f9fa!important
}
a.text-light:focus,
a.text-light:hover {
  color: #dae0e5!important
}
.text-dark {
  color: #343a40!important
}
a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124!important
}
.text-body {
  color: #212529!important
}
.text-muted {
  color: #6c757d!important
}
.text-black-50 {
  color: rgba(0,0,0,.5)!important
}
.text-white-50 {
  color: rgba(255,255,255,.5)!important
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}
.pull-1 {
  right: 8.33333%
}
.pull-2 {
  right: 16.66667%
}
.pull-3 {
  right: 25%
}
.pull-4 {
  right: 33.33333%
}
.pull-5 {
  right: 41.66667%
}
.pull-6 {
  right: 50%
}
.pull-7 {
  right: 58.33333%
}
.pull-8 {
  right: 66.66667%
}
.pull-9 {
  right: 75%
}
.pull-10 {
  right: 83.33333%
}
.pull-11 {
  right: 91.66667%
}
.pull-12 {
  right: 100%
}
.push-1 {
  left: 8.33333%
}
.push-2 {
  left: 16.66667%
}
.push-3 {
  left: 25%
}
.push-4 {
  left: 33.33333%
}
.push-5 {
  left: 41.66667%
}
.push-6 {
  left: 50%
}
.push-7 {
  left: 58.33333%
}
.push-8 {
  left: 66.66667%
}
.push-9 {
  left: 75%
}
.push-10 {
  left: 83.33333%
}
.push-11 {
  left: 91.66667%
}
.push-12 {
  left: 100%
}
@media (min-width:576px) {
  .pull-sm-0 {
    right: auto
  }
  .pull-sm-1 {
    right: 8.33333%
  }
  .pull-sm-2 {
    right: 16.66667%
  }
  .pull-sm-3 {
    right: 25%
  }
  .pull-sm-4 {
    right: 33.33333%
  }
  .pull-sm-5 {
    right: 41.66667%
  }
  .pull-sm-6 {
    right: 50%
  }
  .pull-sm-7 {
    right: 58.33333%
  }
  .pull-sm-8 {
    right: 66.66667%
  }
  .pull-sm-9 {
    right: 75%
  }
  .pull-sm-10 {
    right: 83.33333%
  }
  .pull-sm-11 {
    right: 91.66667%
  }
  .pull-sm-12 {
    right: 100%
  }
  .push-sm-0 {
    left: auto
  }
  .push-sm-1 {
    left: 8.33333%
  }
  .push-sm-2 {
    left: 16.66667%
  }
  .push-sm-3 {
    left: 25%
  }
  .push-sm-4 {
    left: 33.33333%
  }
  .push-sm-5 {
    left: 41.66667%
  }
  .push-sm-6 {
    left: 50%
  }
  .push-sm-7 {
    left: 58.33333%
  }
  .push-sm-8 {
    left: 66.66667%
  }
  .push-sm-9 {
    left: 75%
  }
  .push-sm-10 {
    left: 83.33333%
  }
  .push-sm-11 {
    left: 91.66667%
  }
  .push-sm-12 {
    left: 100%
  }
  .slider-special-layout {
    margin-left: calc((100% - 510px)/ 2)
  }
}
@media (min-width:768px) {
  .pull-md-0 {
    right: auto
  }
  .pull-md-1 {
    right: 8.33333%
  }
  .pull-md-2 {
    right: 16.66667%
  }
  .pull-md-3 {
    right: 25%
  }
  .pull-md-4 {
    right: 33.33333%
  }
  .pull-md-5 {
    right: 41.66667%
  }
  .pull-md-6 {
    right: 50%
  }
  .pull-md-7 {
    right: 58.33333%
  }
  .pull-md-8 {
    right: 66.66667%
  }
  .pull-md-9 {
    right: 75%
  }
  .pull-md-10 {
    right: 83.33333%
  }
  .pull-md-11 {
    right: 91.66667%
  }
  .pull-md-12 {
    right: 100%
  }
  .push-md-0 {
    left: auto
  }
  .push-md-1 {
    left: 8.33333%
  }
  .push-md-2 {
    left: 16.66667%
  }
  .push-md-3 {
    left: 25%
  }
  .push-md-4 {
    left: 33.33333%
  }
  .push-md-5 {
    left: 41.66667%
  }
  .push-md-6 {
    left: 50%
  }
  .push-md-7 {
    left: 58.33333%
  }
  .push-md-8 {
    left: 66.66667%
  }
  .push-md-9 {
    left: 75%
  }
  .push-md-10 {
    left: 83.33333%
  }
  .push-md-11 {
    left: 91.66667%
  }
  .push-md-12 {
    left: 100%
  }
  .slider-special-layout {
    margin-left: calc((100% - 690px)/ 2)
  }
}
@media (min-width:992px) {
  .pull-lg-0 {
    right: auto
  }
  .pull-lg-1 {
    right: 8.33333%
  }
  .pull-lg-2 {
    right: 16.66667%
  }
  .pull-lg-3 {
    right: 25%
  }
  .pull-lg-4 {
    right: 33.33333%
  }
  .pull-lg-5 {
    right: 41.66667%
  }
  .pull-lg-6 {
    right: 50%
  }
  .pull-lg-7 {
    right: 58.33333%
  }
  .pull-lg-8 {
    right: 66.66667%
  }
  .pull-lg-9 {
    right: 75%
  }
  .pull-lg-10 {
    right: 83.33333%
  }
  .pull-lg-11 {
    right: 91.66667%
  }
  .pull-lg-12 {
    right: 100%
  }
  .push-lg-0 {
    left: auto
  }
  .push-lg-1 {
    left: 8.33333%
  }
  .push-lg-2 {
    left: 16.66667%
  }
  .push-lg-3 {
    left: 25%
  }
  .push-lg-4 {
    left: 33.33333%
  }
  .push-lg-5 {
    left: 41.66667%
  }
  .push-lg-6 {
    left: 50%
  }
  .push-lg-7 {
    left: 58.33333%
  }
  .push-lg-8 {
    left: 66.66667%
  }
  .push-lg-9 {
    left: 75%
  }
  .push-lg-10 {
    left: 83.33333%
  }
  .push-lg-11 {
    left: 91.66667%
  }
  .push-lg-12 {
    left: 100%
  }
  .slider-special-layout {
    margin-left: calc((100% - 930px)/ 2)
  }
}
@media (min-width:1200px) {
  .pull-xl-0 {
    right: auto
  }
  .pull-xl-1 {
    right: 8.33333%
  }
  .pull-xl-2 {
    right: 16.66667%
  }
  .pull-xl-3 {
    right: 25%
  }
  .pull-xl-4 {
    right: 33.33333%
  }
  .pull-xl-5 {
    right: 41.66667%
  }
  .pull-xl-6 {
    right: 50%
  }
  .pull-xl-7 {
    right: 58.33333%
  }
  .pull-xl-8 {
    right: 66.66667%
  }
  .pull-xl-9 {
    right: 75%
  }
  .pull-xl-10 {
    right: 83.33333%
  }
  .pull-xl-11 {
    right: 91.66667%
  }
  .pull-xl-12 {
    right: 100%
  }
  .push-xl-0 {
    left: auto
  }
  .push-xl-1 {
    left: 8.33333%
  }
  .push-xl-2 {
    left: 16.66667%
  }
  .push-xl-3 {
    left: 25%
  }
  .push-xl-4 {
    left: 33.33333%
  }
  .push-xl-5 {
    left: 41.66667%
  }
  .push-xl-6 {
    left: 50%
  }
  .push-xl-7 {
    left: 58.33333%
  }
  .push-xl-8 {
    left: 66.66667%
  }
  .push-xl-9 {
    left: 75%
  }
  .push-xl-10 {
    left: 83.33333%
  }
  .push-xl-11 {
    left: 91.66667%
  }
  .push-xl-12 {
    left: 100%
  }
  .slider-special-layout {
    margin-left: calc((100% - 1110px)/ 2)
  }
}
.slider-special-layout__inner {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  overflow: hidden
}
.slider-special-layout__nav {
  background-color: #f4f4f4;
  padding: 20px 15px;
  overflow: hidden
}
.slider-special-layout__nav .slick-dots {
  margin-top: 15px;
  margin-bottom: 15px
}
.slider-special-layout__nav .more-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  line-height: 1
}
.slider-special-layout__nav .more-link:before {
  content: '';
  width: 110px;
  border-top: 1px solid rgba(161,161,161,.35);
  margin-right: 25px
}
.slider-special-layout__nav .more-link a {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 1.3rem;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase
}
.slider-special-layout__nav .more-link a:not(:hover):not(:focus) {
  color: #333
}
.slider-special-layout__container {
  width: 100%
}
@media (min-width:576px) {
  .slider-special-layout__inner {
    margin-left: 0;
    margin-right: 0
  }
  .slider-special-layout__nav .slick-dots {
    margin-left: 15px
  }
  .slider-special-layout__container {
    max-width: 510px
  }
  .slider-special-layout .slick-slider.disable-not-active .slick-slide {
    transition: opacity .3s ease-in-out
  }
}
@media (min-width:768px) {
  .slider-special-layout__container {
    max-width: 690px
  }
}
@media (min-width:992px) {
  .slider-special-layout__container {
    max-width: 930px
  }
}
.slider-special-layout .slick-list {
  overflow: visible
}
@media screen and (min-width:576px) and (prefers-reduced-motion:reduce) {
  .slider-special-layout .slick-slider.disable-not-active .slick-slide {
    transition: none
  }
}
.slick-track {
  display: flex;
  align-items: flex-start
}
.slick-slide {
  flex-shrink: 0;
  outline: 0
}
.slick-slide > div:first-child {
  flex: 0 0 100%;
  width: 100%;
  height: 100%
}
.slick-initialized .slick-slide {
  display: flex;
  height: auto
}
.slick-arrow {
  line-height: 1;
  text-align: center;
  cursor: pointer;
  z-index: 1
}
.slick-arrow.slick-disabled {
  cursor: default
}
.slick-dots {
  line-height: 0;
  font-size: 0
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  user-select: none
}
.slick-dots li:first-child {
  margin-left: 0
}
.slick-dots li.slick-active button {
  color: #f95a3f;
  border-color: currentColor;
  cursor: default
}
.slick-dots li.slick-active button:before {
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background-color: currentColor
}
.slick-dots button {
  position: relative;
  display: block;
  width: 15px;
  height: 15px;
  padding: 0;
  cursor: pointer;
  cursor: hand;
  color: transparent;
  border: 2px solid transparent;
  outline: 0;
  background: 0 0;
  border-radius: 50%
}
.slick-dots button:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: #c7c7c7;
  border-radius: inherit
}
.irs--round .irs-bar {
  background-color: transparent
}
.irs--round .irs-handle {
  top: 30px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 2px solid #f95a3f;
  box-shadow: none
}
.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #fff
}
.irs--round .irs-from,
.irs--round .irs-single,
.irs--round .irs-to {
  background-color: #f95a3f
}
.irs--round .irs-from:before,
.irs--round .irs-single:before,
.irs--round .irs-to:before {
  border-top-color: #f95a3f
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column
}
#app > main {
  flex: 1 0 auto
}
#app > footer {
  flex: 0 0 auto
}
iframe {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0
}
img {
  vertical-align: middle;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
img.lazy {
  max-width: 100%
}
img.loaded {
  height: auto
}
.lazy[src] {
  opacity: 0;
  will-change: opacity;
  transition: opacity .2s
}
.lazy[src].loaded {
  opacity: 1
}
.section {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 0
}
.section--no-pt {
  padding-top: 0!important
}
.section--no-pb {
  padding-bottom: 0!important
}
.section--gray-bg {
  background-color: #fbfbfb
}
.section--bg-gray-2 {
  background-color: #f7f7f7
}
.section .spacer {
  flex: 0 0 100%;
  width: 100%;
  min-height: 1px
}
.section .shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: -5
}
.section .shape svg {
  vertical-align: middle;
  width: 100%;
  min-width: 1000px;
  height: auto
}
.section .decor-el {
  position: absolute;
  display: none;
  z-index: -1
}
.section .decor-el--1 {
  top: 20%;
  right: 0
}
.section .decor-el--2 {
  top: 5%;
  left: 0
}
@media (min-width:768px) {
  .section {
    padding-top: 100px;
    padding-bottom: 100px
  }
}
.circled {
  border-radius: 50%
}
.about-stats {
  line-height: 1
}
.about-stats .__txt-place {
  font-size: 6rem;
  font-weight: 700;
  color: #f95a3f;
  letter-spacing: -.05em
}
.about-stats .__txt-place:last-child {
  margin-bottom: -.12em
}
@media (min-width:992px) {
  .section {
    padding-top: 140px;
    padding-bottom: 140px
  }
  .section .decor-el {
    display: block
  }
  .about-img {
    margin-left: -15px;
    padding-left: 15px;
    width: 49.4vw
  }
  .about-stats .__txt-place {
    font-size: 8rem
  }
}
@media (min-width:1200px) {
  .slider-special-layout__container {
    max-width: 1110px
  }
  .about-stats .__txt-place {
    font-size: 10rem
  }
}
.accordion-container {
  margin-top: 50px;
  margin-bottom: 50px
}
.accordion-container:first-child {
  margin-top: 0
}
.accordion-container:last-child {
  margin-bottom: 0
}
.accordion-item {
  border-bottom: 1px solid #e3e3e3
}
.accordion-item.active .accordion-toggler {
  cursor: default
}
.accordion-item.active .accordion-toggler i:before {
  transform: rotate(-90deg)
}
.accordion-toggler {
  position: relative;
  padding: 15px 25px 15px 0;
  cursor: pointer
}
.accordion-toggler i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  color: #f95a3f;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,color .3s ease-in-out
}
.accordion-toggler i:after,
.accordion-toggler i:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid currentColor;
  transform-origin: center;
  transition: transform .4s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .accordion-toggler i,
  .accordion-toggler i:after,
  .accordion-toggler i:before,
  .lazy[src] {
    transition: none
  }
}
.accordion-toggler i:before {
  width: 2px;
  height: 10px;
  margin-left: -1px;
  margin-top: -5px
}
.accordion-toggler i:after {
  width: 10px;
  height: 2px;
  margin-left: -5px;
  margin-top: -1px
}
.accordion-title {
  margin: 0
}
.accordion-content {
  display: none
}
.accordion-content__inner {
  padding-bottom: 15px
}
.accordion-content p {
  margin-top: 15px;
  margin-bottom: 15px
}
@media (min-width:576px) {
  .slider-special-layout .slick-slider.disable-not-active .slick-slide:not(.slick-active) {
    opacity: .5;
    pointer-events: none
  }
  .accordion-toggler {
    padding-top: 23px;
    padding-bottom: 23px
  }
  .accordion-content__inner {
    padding-bottom: 30px
  }
}
.alert {
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 27px 17px;
  border: 3px solid;
  line-height: 1.4
}
.alert:first-child {
  margin-top: 0
}
.alert:last-child {
  margin-bottom: 0
}
.alert__ico {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
  border-radius: 50%
}
.alert__close {
  display: none;
  position: absolute;
  top: 28px;
  right: 20px;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  outline: 0;
  appearance: none
}
.alert__close:not(:disabled):not(.disabled) {
  cursor: pointer
}
.alert a {
  color: inherit
}
.alert--dismissible {
  padding-right: 67px
}
.alert--dismissible .alert__close {
  display: block
}
.alert--success {
  border-color: #72bf4c
}
.alert--info {
  border-color: #4d7ec0
}
.alert--warning {
  border-color: #f48d37
}
.alert--danger {
  border-color: #f95a3f
}
.alert--s1 {
  color: #fff
}
.alert--s1.alert--success {
  background-color: #72bf4c
}
.alert--s1.alert--success .alert__ico {
  background-color: #508c32
}
.alert--s1.alert--info {
  background-color: #4d7ec0
}
.alert--s1.alert--info .alert__ico {
  background-color: #32598e
}
.alert--s1.alert--warning {
  background-color: #f48d37
}
.alert--s1.alert--warning .alert__ico {
  background-color: #d3660c
}
.alert--s1.alert--danger {
  background-color: #f95a3f
}
.alert--s1.alert--danger .alert__ico {
  background-color: #e42707
}
.alert--s1 .alert__close {
  color: rgba(255,255,255,.5)
}
.alert--s2 {
  color: #333
}
.alert--s2.alert--success .alert__ico {
  background-color: #72bf4c
}
.alert--s2.alert--info .alert__ico {
  background-color: #4d7ec0
}
.alert--s2.alert--warning .alert__ico {
  background-color: #f48d37
}
.alert--s2.alert--danger .alert__ico {
  background-color: #f95a3f
}
.alert--s2 .alert__close {
  color: #b1b1b1
}
.block-info {
  background-color: #f95a3f;
  padding: 20px 15px;
  color: #ffbdb2
}
.block-info .__ico {
  display: inline-block;
  vertical-align: top;
  margin-left: -10px;
  margin-bottom: 20px
}
.block-info .__title {
  color: #fff
}
@media (min-width:576px) {
  .block-info {
    padding: 30px
  }
  .block-info .__ico {
    margin-right: 20px;
    margin-bottom: 0
  }
}
.check-list {
  line-height: 1.2;
  text-align: left;
  color: #25272a
}
.check-list li {
  margin-top: 20px;
  padding-left: 35px
}
.check-list li:first-child {
  margin-top: 0
}
.check-list .ico-checked,
.check-list .ico-unchecked {
  float: left;
  margin-left: -35px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 1px solid;
  line-height: 18px;
  font-size: 1rem;
  text-align: center;
  border-radius: 50%
}
.check-list .ico-checked:before,
.check-list .ico-unchecked:before {
  vertical-align: middle
}
.check-list .ico-checked {
  color: #25e986;
  border-color: #25e986
}
.comments-list {
  margin-top: 30px;
  margin-bottom: 30px
}
.comments-list:first-child {
  margin-top: 0
}
.comments-list:last-child {
  margin-bottom: 0
}
.comments-list .comment {
  margin-top: 50px;
  font-size: 1.6rem
}
.comments-list .comment:first-child {
  margin-top: 0
}
.comments-list .comment__author-img {
  width: 70px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 50%
}
.comments-list .comment__author-name {
  display: block;
  line-height: 1;
  font-size: 2rem;
  font-weight: 700;
  color: #333
}
.comments-list .comment .__reply {
  min-width: 1em
}
.comments-list .comments-list {
  margin-top: 50px;
  padding-left: 30px
}
@media (min-width:992px) {
  .comments-list .comment__author-img {
    margin-right: 30px
  }
  .comments-list ul {
    padding-left: 50px
  }
}
.contact-info {
  line-height: 1;
  text-align: left
}
.contact-info > li {
  margin-top: 20px;
  line-height: 1.4;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  color: #d3d3d3
}
.contact-info > li:first-child {
  margin-top: 0
}
.contact-info .__label {
  margin-bottom: 10px;
  line-height: 1.2;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #5c5c5c
}
.contact-info a:not([class]) {
  color: inherit
}
.counter {
  margin-top: 30px;
  margin-bottom: 30px
}
.counter:first-child {
  margin-top: 0
}
.counter:last-child {
  margin-bottom: 0
}
.counter .__item {
  position: relative;
  width: 100%
}
.counter .__ico {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.counter .__ico > img,
.counter .__ico > svg {
  display: block
}
.counter .__ico + .__content {
  margin-top: 10px
}
.counter .__content {
  line-height: 1;
  font-weight: 800;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.counter .__title {
  font-family: inherit;
  letter-spacing: -.05em
}
.counter .__count {
  line-height: .8
}
.counter .__count:before {
  pointer-events: none;
  display: block;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  content: attr(data-to) attr(data-after-text)
}
.counter .__count:after {
  content: attr(data-after-text)
}
.counter--s1 .__inner {
  margin-bottom: -50px
}
.counter--s1 .__item {
  margin-bottom: 50px
}
.counter--s1 .__count {
  font-size: 10rem;
  color: #f95a3f
}
.counter--s1 .__count:first-child {
  margin-top: -.1em
}
.counter--s2 .__inner {
  margin-bottom: -50px
}
.counter--s2 .__item {
  margin-bottom: 50px
}
.counter--s2 .__count {
  font-size: 6rem;
  color: #333
}
.counter--s3 .__inner {
  margin-bottom: -50px
}
.counter--s3 .__item {
  margin-bottom: 50px
}
.counter--s3 .__count {
  font-size: 8rem;
  color: #f95a3f
}
.counter--s3 .__count:first-child {
  margin-top: -.1em
}
form {
  position: relative
}
form .input-wrp {
  position: relative;
  display: block;
  width: 100%;
  line-height: 1;
  margin-bottom: 20px
}
form .textfield {
  display: block;
  float: none;
  width: 100%;
  background: padding-box none;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  line-height: 1.2;
  font-size: 1.6rem;
  color: #777;
  appearance: none;
  outline: 0;
  padding: 15px 0;
  box-shadow: none;
  border-radius: 0;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,color .3s ease-in-out
}
form .textfield::-webkit-input-placeholder {
  color: #777;
  transition: color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  form .textfield {
    transition: none
  }
  form .textfield::-webkit-input-placeholder {
    transition: none
  }
}
form .textfield::-moz-placeholder {
  color: #777;
  transition: color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  form .textfield::-moz-placeholder {
    transition: none
  }
}
form .textfield:-moz-placeholder {
  color: #777;
  transition: color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  form .textfield:-moz-placeholder {
    transition: none
  }
}
form .textfield:-ms-input-placeholder {
  color: #777;
  transition: color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  form .textfield:-ms-input-placeholder {
    transition: none
  }
}
form .textfield.focus:not([disabled]),
form .textfield:focus:not([disabled]) {
  border-color: #131313
}
form .textfield.valid:not([disabled]) {
  border-color: #46b36a!important;
  color: #46b36a!important
}
form .textfield.valid:not([disabled])::-webkit-input-placeholder {
  color: #46b36a
}
form .textfield.valid:not([disabled])::-moz-placeholder {
  color: #46b36a
}
form .textfield.valid:not([disabled]):-moz-placeholder {
  color: #46b36a
}
form .textfield.valid:not([disabled]):-ms-input-placeholder {
  color: #46b36a
}
form .textfield.invalid:not([disabled]) {
  border-color: #f94e4e!important;
  color: #f94e4e!important
}
form .textfield.invalid:not([disabled])::-webkit-input-placeholder {
  color: #f94e4e
}
form .textfield.invalid:not([disabled])::-moz-placeholder {
  color: #f94e4e
}
form .textfield.invalid:not([disabled]):-moz-placeholder {
  color: #f94e4e
}
form .textfield.invalid:not([disabled]):-ms-input-placeholder {
  color: #f94e4e
}
form .textfield:disabled::-webkit-input-placeholder {
  color: #ccc
}
form .textfield:disabled::-moz-placeholder {
  color: #ccc
}
form .textfield:disabled:-moz-placeholder {
  color: #ccc
}
form .textfield:disabled:-ms-input-placeholder {
  color: #ccc
}
form .nice-select.textfield,
form input.textfield,
form select.textfield {
  height: 50px
}
form textarea {
  resize: vertical;
  min-height: 130px;
  height: 100%
}
form button[type=submit] {
  cursor: pointer;
  box-shadow: none;
  outline: 0;
  margin-top: 10px
}
.form--horizontal button[type=submit] {
  margin-top: 0
}
@media (min-width:576px) {
  .form--horizontal .input-wrp {
    margin: 0
  }
}
.form-subscribe .textfield {
  color: #fff;
  border-color: rgba(255,255,255,.5)
}
.form-subscribe .textfield::-webkit-input-placeholder {
  color: inherit
}
.form-subscribe .textfield::-moz-placeholder {
  color: inherit
}
.form-subscribe .textfield:-moz-placeholder {
  color: inherit
}
.form-subscribe .textfield:-ms-input-placeholder {
  color: inherit
}
.form-subscribe .textfield.focus:not([disabled]),
.form-subscribe .textfield:focus:not([disabled]) {
  border-color: #fff
}
select {
  border: none
}
label {
  cursor: pointer
}
.checkfield,
.radiofield {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  padding-left: 30px;
  line-height: 1.2;
  font-size: 1.6rem
}
.checkfield i,
.radiofield i {
  position: relative;
  float: left;
  width: 17px;
  height: 17px;
  margin-left: -30px;
  border: 2px solid #cdcdcd;
  overflow: hidden
}
.checkfield i:before,
.radiofield i:before {
  transition: transform .25s cubic-bezier(.23,1,.32,1)
}
.checkfield input[type=checkbox],
.radiofield input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  clip: rect(2px,2px,2px,2px)
}
.checkfield input[type=checkbox]:disabled ~ *,
.radiofield input[type=radio]:disabled ~ * {
  opacity: .6
}
.radiofield input[type=radio]:checked ~ i:before {
  transform: scale(1)
}
.radiofield input[type=radio]:checked ~ span a {
  color: #f95a3f
}
.radiofield i {
  border-radius: 50%
}
.radiofield i:before {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  transform: scale(0);
  background-color: #f95a3f;
  border-radius: inherit
}
.checkfield input[type=checkbox]:checked ~ i:before {
  transform: scale(1) rotate(45deg)
}
.checkfield input[type=checkbox]:checked ~ span a {
  color: #f95a3f
}
.checkfield i {
  background-color: #fff
}
.checkfield i:before {
  content: "";
  display: block;
  width: 5px;
  height: 9px;
  margin: auto;
  border: 2px solid #f95a3f;
  border-top: none;
  border-left: none;
  transform: scale(0) rotate(45deg);
  color: #f95a3f
}
.checkfield i:last-child {
  margin-right: 0
}
.nice-select {
  padding-right: 30px!important
}
.nice-select:after {
  width: 8px!important;
  height: 8px!important;
  margin-top: -5px!important;
  border-bottom: 2px solid #525252!important;
  border-right: 2px solid #525252!important
}
.nice-select .option {
  line-height: 40px
}
.nice-select .option.focus,
.nice-select .option.selected.focus,
.nice-select .option:hover {
  background-color: #f1f1f1!important
}
.icon-list--s1 .__image img,
.icon-list--s2 .__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto
}
.icon-list .__item {
  position: relative;
  width: 100%;
  text-align: center
}
.icon-list .__image {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden
}
.icon-list .__ico {
  display: inline-block;
  vertical-align: top;
  color: #f95a3f
}
.icon-list .__ico > svg {
  vertical-align: middle;
  fill: currentColor
}
.icon-list--s1 .__inner {
  margin-bottom: -60px
}
.icon-list--s1 .__item {
  margin-bottom: 60px
}
.icon-list--s1 .__image {
  min-height: 140px
}
.icon-list--s1 .__image:before {
  content: "";
  display: block;
  padding-top: 78.43137%
}
.icon-list--s2 .__inner {
  margin-bottom: -60px
}
.icon-list--s2 .__item {
  margin-bottom: 60px
}
.icon-list--s2 .__image {
  min-height: 170px
}
.icon-list--s2 .__image:before {
  content: "";
  display: block;
  padding-top: 98.03922%
}
.icon-list--s3 .__inner {
  margin-bottom: -50px
}
.icon-list--s3 .__item {
  margin-bottom: 50px
}
.icon-list--s3 .__ico {
  margin-bottom: -5px
}
.icon-list--s3 .__ico:last-child {
  margin-bottom: 0
}
.icon-list--s4 .__inner {
  margin-bottom: -50px
}
.icon-list--s4 .__item {
  margin-bottom: 50px
}
.info-table {
  padding: 0 1px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}
.info-table table {
  min-width: 400px;
  line-height: 1.4;
  font-size: 1.6rem
}
.info-table table td {
  padding: 15px 10px;
  height: 55px
}
.info-table table td:first-child {
  padding-left: 20px
}
.info-table table td:last-child {
  padding-right: 20px
}
.info-table thead {
  color: #25272a
}
.info-table tbody td:first-child,
.info-table thead td {
  font-weight: 700
}
.info-table--s1 thead {
  border-bottom: 1px solid #e3e3e3
}
.info-table--s1 tbody tr:nth-of-type(even) {
  background-color: #f9f9f9
}
.info-table--s2 thead {
  background-color: #2e2e2e;
  color: #fff
}
.info-table--s2 tbody tr {
  border-bottom: 1px solid #e3e3e3
}
.info-table--s3 thead {
  background-color: #e3e3e3;
  border: 1px solid #e3e3e3
}
.info-table--s3 tbody tr:nth-of-type(even) {
  background-color: #f9f9f9
}
.info-table--s3 tbody td {
  border: 1px solid #e3e3e3
}
.info-table--s4 thead {
  border-bottom: 1px solid #e3e3e3
}
.info-table--s4 tbody tr:nth-of-type(even) {
  border: 1px solid #e3e3e3
}
.pagination {
  flex-wrap: wrap;
  align-items: center;
  margin: -5px;
  border-radius: 0
}
.pagination .page-item {
  margin: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.pagination .page-item:not(.active):hover .page-link {
  background-color: inherit;
  border-color: #f95a3f;
  color: #f95a3f
}
.pagination .page-item.active .page-link {
  background-color: #f95a3f;
  border-color: #f95a3f;
  cursor: default
}
.pagination .page-item > span {
  display: inline-block;
  vertical-align: top;
  line-height: 1
}
.pagination .page-link {
  min-width: 40px;
  height: 40px;
  margin-left: 0;
  padding: 0;
  line-height: 38px;
  font-size: 1.6rem;
  color: #333;
  text-align: center;
  text-decoration: none;
  border: 2px solid #dcdcdc;
  border-radius: 0!important;
  box-shadow: none;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,color .3s ease-in-out
}
.pagination .page-link i {
  line-height: 1
}
.product-brochure {
  padding: 30px 15px;
  background: url(../img/product_brochure_bg.png) -145px -15px no-repeat #2a6ab7;
  line-height: 1.4;
  font-weight: 700;
  text-align: right;
  color: #fff
}
.product-brochure__inner {
  display: inline-block;
  vertical-align: top;
  text-align: left
}
.product-brochure .custom-btn {
  border-color: currentColor;
  color: #fff
}
.product-brochure .custom-btn:focus,
.product-brochure .custom-btn:hover {
  background-color: #fff;
  border-color: #fff;
  color: #333
}
@media (min-width:400px) {
  .product-brochure {
    background-position: -80px -15px
  }
}
@media (min-width:576px) {
  .product-brochure {
    padding: 40px 30px
  }
}
.product-label {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 65px;
  padding: 7px 15px;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  color: #fff;
  user-select: none
}
.product-label--new {
  background-color: #4cdcae
}
.product-label--discount {
  background-color: #f1492c
}
.product-price {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.product-price__item {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0
}
.product-price__item--new {
  color: #f95a3f
}
.product-price__item--old {
  color: #c5c5c5
}
.quantity-counter {
  position: relative
}
.quantity-counter input {
  display: block;
  float: none;
  width: 100%;
  height: 50px;
  background: padding-box none;
  border: 2px solid #f1f1f1;
  line-height: 1.2;
  font-size: 1.8rem;
  text-align: center;
  color: #777;
  appearance: none;
  outline: 0;
  padding: 0 38px;
  box-shadow: none;
  border-radius: 0;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,color .3s ease-in-out
}
.quantity-counter .__btn {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 2.8rem;
  text-align: center;
  color: #333;
  cursor: pointer;
  user-select: none
}
.quantity-counter .__btn:after,
.quantity-counter .__btn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: currentColor
}
.quantity-counter .__btn--minus {
  left: 5px
}
.quantity-counter .__btn--minus:before {
  content: "";
  margin-top: -1px;
  margin-left: -6px;
  width: 12px;
  height: 2px
}
.quantity-counter .__btn--plus {
  right: 5px
}
.quantity-counter .__btn--plus:before {
  content: "";
  margin-top: -1px;
  margin-left: -6px;
  width: 12px;
  height: 2px
}
.quantity-counter .__btn--plus:after {
  content: "";
  margin-top: -6px;
  margin-left: -1px;
  width: 2px;
  height: 12px
}
@media (min-width:576px) {
  .quantity-counter .__btn--minus {
    left: 10px
  }
  .quantity-counter .__btn--plus {
    right: 10px
  }
}
.rating {
  border: none;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.rating__item {
  display: inline-block;
  vertical-align: top;
  margin-left: .4em;
  line-height: 1;
  font-size: 1.5rem;
  color: #cfcfcf;
  letter-spacing: 0;
  user-select: none;
  transition: color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .checkfield i:before,
  .pagination .page-link,
  .quantity-counter input,
  .radiofield i:before,
  .rating__item {
    transition: none
  }
}
.rating__item:first-child {
  margin-left: 0
}
.rating__item--active {
  color: #f1b526
}
.rating__item i {
  display: block
}
.rating input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  clip: rect(2px,2px,2px,2px)
}
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #f1b526
}
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffd778
}
.tab-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff
}
@media (min-width:768px) {
  .tab-container--hor .tab-nav--top {
    margin-bottom: -2px
  }
  .tab-container--hor .tab-nav__list {
    flex-direction: row
  }
  .tab-container--hor .tab-nav__item {
    margin-left: -2px
  }
  .tab-container--hor .tab-nav__item:first-child {
    margin-left: 0
  }
  .tab-container--ver {
    flex-direction: row
  }
  .tab-container--ver .tab-nav--left {
    margin-right: -2px
  }
  .tab-container--ver .tab-nav__item {
    margin-top: -2px
  }
  .tab-container--ver .tab-nav__item:first-child {
    margin-top: 0
  }
}
.tab-nav {
  position: relative;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px;
  z-index: 3
}
.tab-nav__list {
  display: flex;
  flex-direction: column;
  min-height: 100%
}
.tab-nav__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent;
  line-height: 1;
  cursor: pointer;
  transition: .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .tab-nav__item {
    transition: none
  }
}
.tab-nav__item.active,
.tab-nav__item.active .tab-nav__link {
  cursor: default
}
.tab-nav__link {
  display: block;
  line-height: 36px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #a3a3a3;
  text-decoration: none!important;
  letter-spacing: 0;
  border: none;
  box-shadow: none;
  outline: 0;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none
}
.tab-content {
  position: relative;
  flex-grow: 1;
  width: 100%
}
.tab-content__item {
  top: 0;
  left: 0;
  padding-top: 15px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out,visibility .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .tab-content__item {
    transition: none
  }
}
.tab-content__item.is-visible {
  position: static;
  top: auto;
  left: auto;
  visibility: visible;
  z-index: 2;
  opacity: 1
}
@media (min-width:768px) {
  .tab-nav__link {
    line-height: 46px
  }
  .tab-container--s1.tab-container--hor .tab-nav__item.active {
    border-bottom-color: #fff
  }
  .tab-container--s1.tab-container--ver .tab-nav__item.active {
    border-right-color: #fff
  }
}
.tab-container--s1 .tab-nav__item.active {
  border-color: #e4e4e4
}
.tab-container--s1 .tab-nav__item.active .tab-nav__link,
.tab-container--s1 .tab-nav__item:hover .tab-nav__link {
  color: #333
}
.tab-container--s1 .tab-nav__link {
  padding: 0 20px
}
@media (min-width:992px) {
  .tab-container--s1 .tab-nav__link {
    padding: 0 30px
  }
}
.tab-container--s1 .tab-content {
  border: 2px solid #e4e4e4
}
.tab-container--s1 .tab-content__item {
  padding: 20px 15px
}
.tab-container--s2.tab-container .tab-nav__item.active {
  border-bottom-color: #f95a3f
}
@media (min-width:768px) {
  .tab-container--s1 .tab-content__item {
    padding: 35px 30px
  }
  .tab-container--s2.tab-container--hor .tab-content {
    border-top: 2px solid #e4e4e4
  }
  .tab-container--s2.tab-container--hor .tab-content__item {
    padding-top: 35px
  }
  .tab-container--s2.tab-container--ver .tab-nav__item.active {
    border-right-color: #f95a3f;
    border-bottom-color: transparent
  }
  .tab-container--s2.tab-container--ver .tab-content {
    border-left: 2px solid #e4e4e4
  }
  .tab-container--s2.tab-container--ver .tab-content__item {
    padding-top: 0;
    padding-left: 30px
  }
  .video-block .__bg .btn_play {
    margin-left: 10%
  }
}
.tab-container--s2 .tab-nav__item.active .tab-nav__link,
.tab-container--s2 .tab-nav__item:hover .tab-nav__link {
  color: #f95a3f
}
.tab-container--s2 .tab-nav__link {
  padding: 0 18px
}
.tags-list {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.tags-list__list {
  margin-top: -7px;
  margin-left: -7px
}
.tags-list__item {
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
  margin-left: 7px
}
.tags-list__link {
  display: block;
  padding: 4px 5px;
  border: 1px solid #d9d9d9;
  line-height: 1.2;
  font-size: 1.2rem;
  text-decoration: none;
  letter-spacing: 1px;
  color: #a5a5a5;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  box-shadow: none;
  -webkit-user-drag: none;
  user-drag: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,color .3s ease-in-out
}
.tags-list__link.active,
.tags-list__link:focus,
.tags-list__link:hover {
  background-color: #f95a3f;
  border-color: #f95a3f;
  color: #fff
}
#btn-to-top-wrap {
  display: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 3
}
@media only screen and (min-width:561px) {
  #btn-to-top-wrap {
    right: 25px;
    bottom: 25px
  }
}
#btn-to-top {
  display: block;
  width: 44px;
  height: 44px;
  line-height: 42px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #f84526;
  opacity: .7;
  transition: opacity .3s ease-in-out
}
#btn-to-top:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-bottom: 6px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent
}
#btn-to-top:hover {
  opacity: 1
}
.video-block .__bg {
  display: flex;
  align-items: center;
  height: 290px;
  padding: 30px 15px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover
}
.video-block .btn_play {
  display: inline-block;
  vertical-align: top;
  line-height: 1.4;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none
}
.video-block .btn_play i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 56px;
  height: 56px;
  margin-right: 20px
}
.video-block .btn_play i:after,
.video-block .btn_play i:before {
  content: "";
  position: absolute;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,transform .4s ease-in-out
}
.video-block .btn_play i:before {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%
}
.video-block .btn_play i:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 3px;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 9px;
  border-color: transparent transparent transparent #f95a3f
}
.video-block .btn_play--white {
  color: #fff
}
.video-block .btn_play--white i:before {
  background-color: #fff
}
.video-block .btn_play--white:focus i:before,
.video-block .btn_play--white:hover i:before {
  background-color: #f95a3f
}
.video-block .btn_play--white:focus i:after,
.video-block .btn_play--white:hover i:after {
  border-color: transparent transparent transparent #fff
}
.video-block .btn_play--dark {
  color: #333
}
.video-block .btn_play--dark i:before {
  background-color: #25272a
}
.video-block .btn_play:focus i:before,
.video-block .btn_play:hover i:before {
  transform: scale(1.25)
}
@media (min-width:576px) {
  .video-block .__bg {
    padding: 30px
  }
}
.widget {
  position: relative;
  margin-top: 40px
}
.widget:first-child {
  margin-top: 0
}
.widget:first-child .widget-title {
  margin-top: -.2em
}
.widget-title {
  color: #f95a3f
}
.widget-title:after {
  content: "."
}
@media only screen and (min-width:992px) {
  .widget {
    margin-top: 60px
  }
}
.widget--search button[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  border: none;
  margin: 0;
  color: #f95a3f
}
.widget--search button[type=submit] + .textfield {
  padding-right: 25px
}
.widget--archive .list__item,
.widget--categories .list__item {
  position: relative;
  margin-top: 15px;
  line-height: 1.3;
  font-weight: 700
}
.widget--archive .list__item:first-child,
.widget--categories .list__item:first-child {
  margin-top: 0
}
.widget--archive .list__item__link,
.widget--categories .list__item__link {
  display: block;
  text-decoration: none
}
.widget--archive .list__item__link:not(:hover),
.widget--categories .list__item__link:not(:hover) {
  color: #333
}
.widget--archive .list__item__link > span,
.widget--categories .list__item__link > span {
  margin-left: 15px;
  float: right;
  color: #999
}
.widget--archive .list__item__arrow,
.widget--categories .list__item__arrow {
  position: absolute;
  top: 1px;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transform-origin: 50% 50%;
  transition: transform .3s ease-in-out
}
.widget--archive .list__item__arrow:before,
.widget--categories .list__item__arrow:before {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  margin: 6px auto auto;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: #25272a;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: border-color .3s ease-in-out
}
.widget--archive .list__item__arrow.is-active,
.widget--categories .list__item__arrow.is-active {
  transform: rotate(180deg)
}
.widget--archive .list__item__arrow.is-active:before,
.widget--categories .list__item__arrow.is-active:before {
  border-color: #f95a3f
}
.widget--archive .list__submenu,
.widget--categories .list__submenu {
  display: none;
  margin-left: 15px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent
}
.widget--archive .list__submenu .list__item,
.widget--categories .list__submenu .list__item {
  margin-top: 13px
}
.widget--archive .list__submenu .list__item__link,
.widget--categories .list__submenu .list__item__link {
  font-size: 1.6rem;
  color: #777
}
.widget--archive .list__submenu .list__item__link:before,
.widget--categories .list__submenu .list__item__link:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  margin-top: 2px;
  border-top: 2px solid currentColor;
  pointer-events: none;
  transition: width .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  #btn-to-top,
  .tags-list__link,
  .video-block .btn_play i:after,
  .video-block .btn_play i:before,
  .widget--archive .list__item__arrow,
  .widget--archive .list__item__arrow:before,
  .widget--archive .list__submenu .list__item__link:before,
  .widget--categories .list__item__arrow,
  .widget--categories .list__item__arrow:before,
  .widget--categories .list__submenu .list__item__link:before {
    transition: none
  }
}
.widget--archive .list__submenu .list__item__link.active,
.widget--archive .list__submenu .list__item__link:focus,
.widget--archive .list__submenu .list__item__link:hover,
.widget--categories .list__submenu .list__item__link.active,
.widget--categories .list__submenu .list__item__link:focus,
.widget--categories .list__submenu .list__item__link:hover {
  color: #f95a3f
}
.widget--archive .list__submenu .list__item__link.active:before,
.widget--archive .list__submenu .list__item__link:focus:before,
.widget--archive .list__submenu .list__item__link:hover:before,
.widget--categories .list__submenu .list__item__link.active:before,
.widget--categories .list__submenu .list__item__link:focus:before,
.widget--categories .list__submenu .list__item__link:hover:before {
  width: 30px
}
.widget--posts article {
  margin-top: 20px;
  line-height: 1.4
}
.widget--posts article:first-child {
  margin-top: 0
}
.widget--posts .__image-wrap {
  width: 33%;
  max-width: 100px;
  padding-right: 15px
}
.widget--posts .__image {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  padding-top: 100%;
  overflow: hidden
}
.widget--posts .__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%"
}
.widget--posts .__title {
  margin-bottom: 10px;
  font-size: 1.8rem
}
.widget--instagram .widget-title:after {
  content: none
}
.widget--instagram .widget-title i {
  float: right;
  color: #333
}
.widget--instagram .instafeed {
  margin: 0!important
}
.widget--instagram .instafeed .row {
  margin-left: -5px;
  margin-right: 0
}
.widget--instagram .instafeed .row > .col,
.widget--instagram .instafeed .row > [class*=col-] {
  padding-left: 5px;
  padding-right: 0
}
.widget--instagram .instafeed .__item {
  margin-top: 5px
}
.widget--instagram .instafeed .__image {
  min-height: 1px;
  padding-top: 100%
}
.widget--brands {
  font-weight: 700;
  color: #25272a
}
.widget--brands ul {
  line-height: 1
}
.widget--brands li {
  margin-top: 20px
}
.widget--brands li:first-child {
  margin-top: 0
}
.widget--brands .checkfield {
  margin-bottom: 0
}
.widget--colors ul {
  margin-top: -10px;
  margin-left: -10px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.widget--colors li {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  margin-left: 10px
}
.widget--colors .color-label {
  display: block
}
.widget--colors .color-label input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  clip: rect(2px,2px,2px,2px)
}
.widget--colors .color-label input[type=checkbox]:checked ~ span:before {
  transform: scale(1)
}
.widget--colors .color-label span {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer
}
.widget--colors .color-label span:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border-radius: inherit;
  background-color: #fff;
  transform: scale(0);
  transition: transform .25s cubic-bezier(.23,1,.32,1)
}
.widget--characteristics {
  font-weight: 700;
  color: #25272a
}
.widget--characteristics ul {
  line-height: 1
}
.widget--characteristics li {
  margin-top: 20px
}
.widget--characteristics li:first-child {
  margin-top: 0
}
.widget--characteristics .radiofield {
  margin-bottom: 0
}
.advantages {
  max-width: 1520px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0
}
.advantages .__inner {
  overflow: hidden
}
.advantages .__item {
  position: relative;
  width: 100%;
  padding: 20px 0
}
@media (min-width:576px) {
  .advantages .__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
  }
  .advantages .__item:before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    bottom: 0;
    border-left: 1px solid #e9e9e9
  }
}
.advantages .__ico {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  line-height: 1
}
.advantages .__ico > svg {
  vertical-align: middle
}
.advantages .__title {
  margin: 0
}
.auth-form {
  background-color: #fff;
  padding: 30px 15px 25px;
  line-height: 1.2;
  font-size: 1.6rem;
  color: #333
}
.auth-form .input-wrp {
  margin-bottom: 10px
}
.auth-form .textfield-ico {
  position: absolute;
  top: 11px;
  right: 0;
  line-height: 30px;
  font-size: 1.5rem;
  color: #8f8a86;
  cursor: pointer
}
.auth-form .textfield-ico + .textfield {
  padding-right: 20px
}
.auth-form .checkfield {
  color: #333
}
.auth-form button[type=submit] {
  display: block;
  width: 100%;
  min-width: 0;
  margin-top: 45px;
  margin-bottom: 45px
}
.auth-form button[type=submit]:last-child {
  margin-bottom: 0
}
.auth-form .__link {
  display: inline-block;
  vertical-align: top
}
@media (min-width:576px) {
  .auth-form {
    padding: 40px 30px 35px
  }
}
.brands-list .__inner {
  margin-bottom: -30px
}
.brands-list .__item {
  position: relative;
  vertical-align: top;
  width: 100%;
  margin-bottom: 30px;
  text-align: center
}
.brands-list .__image {
  margin: auto;
  opacity: .2;
  transition: opacity .5s ease-in-out
}
.brands-list .__image a {
  display: inline-block;
  vertical-align: top
}
.brands-list .__image:hover {
  opacity: .8
}
.brands-list--slider {
  overflow: hidden
}
.brands-list--slider .slick-track {
  align-items: center
}
.brands-list--slider .slick-slider > .slick-dots {
  margin-top: 30px;
  text-align: center
}
.brands-list--slider .slick-list {
  margin-left: -15px;
  margin-right: -15px
}
.brands-list--slider .slick-slide {
  padding-left: 15px;
  padding-right: 15px
}
.brands-list--slider .__item {
  margin-bottom: 0
}
.brands-list--slider .__image {
  transform: translateZ(0)
}
.brands-list--slider .__image img {
  margin-left: auto;
  margin-right: auto
}
.cart__table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}
.cart__table table {
  min-width: 650px
}
.cart__table thead {
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #25272a
}
.cart__table thead tr {
  border-bottom: 2px solid #f1f1f1
}
.cart__table thead td {
  padding-bottom: 25px
}
.cart__table tbody tr {
  border-bottom: 1px solid #f1f1f1
}
.cart__table tbody td {
  padding-top: 30px;
  padding-bottom: 29px
}
.cart__table td {
  padding-left: 15px
}
.cart__table td:first-child {
  padding-left: 0
}
.cart__table .__image {
  position: relative;
  width: 120px;
  height: 120px;
  background-color: #fbfbfb;
  margin-right: 20px
}
.cart__table .__image a {
  display: block;
  width: 100%;
  height: 100%
}
.cart__table .__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  max-height: 80%
}
.cart__table .__name {
  display: inline-block;
  vertical-align: top;
  line-height: 1.4;
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none
}
.cart__table .__name:last-child {
  margin-bottom: 0
}
.cart__table .__name:not(:hover):not(:focus) {
  color: #333
}
.cart__table .__price,
.cart__table .__total {
  font-weight: 700;
  white-space: nowrap
}
.cart__table .__price {
  color: #333
}
.cart__table .__total {
  color: #f95a3f
}
.cart__table .__remove {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center
}
.cart__table .quantity-counter {
  width: 1px;
  min-width: 130px
}
@media (min-width:992px) {
  .cart__table .__image {
    margin-right: 40px
  }
  .cart__table .quantity-counter {
    min-width: 150px
  }
}
.cart__coupon {
  width: 100%;
  max-width: 440px
}
.cart__total {
  line-height: 1.3;
  min-width: 230px;
  font-weight: 700;
  color: #333
}
.cart__total tbody td {
  padding-bottom: 10px
}
.cart__total tfoot td {
  padding-top: 20px
}
.checkout__table {
  margin-top: 30px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}
.checkout__table:first-child {
  margin-top: 0
}
.checkout__table table {
  min-width: 380px
}
.checkout__table tr:first-child td {
  padding-top: 0
}
.checkout__table td {
  padding-top: 20px;
  padding-left: 20px
}
.checkout__table td:first-child {
  padding-left: 0
}
.checkout__table .__image {
  position: relative;
  width: 120px;
  height: 120px;
  background-color: #fbfbfb;
  margin-right: 15px
}
@media (min-width:992px) {
  .checkout__table .__image {
    margin-right: 35px
  }
}
.checkout__table .__image a {
  display: block;
  width: 100%;
  height: 100%
}
.checkout__table .__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  max-height: 80%
}
.checkout__table .__name {
  display: inline-block;
  vertical-align: top;
  line-height: 1.4;
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none
}
.checkout__table .__name:last-child {
  margin-bottom: 0
}
.checkout__table .__name:not(:hover):not(:focus) {
  color: #333
}
.checkout__table .__amount {
  line-height: 1.2;
  font-size: 1.6rem;
  font-weight: 700;
  color: #999
}
.checkout__table .__total {
  font-weight: 700;
  color: #f95a3f;
  white-space: nowrap
}
.checkout__total {
  width: 100%;
  max-width: 370px;
  margin-top: 50px;
  padding-top: 40px;
  border-top: 1px solid #f0f0f0;
  line-height: 1.3;
  font-weight: 700;
  color: #333
}
.checkout__total tbody td {
  padding-bottom: 10px
}
.checkout__total tfoot td {
  padding-top: 10px
}
.company-contacts {
  margin-top: 30px;
  margin-bottom: 30px
}
.company-contacts:first-child {
  margin-top: 0
}
.company-contacts:last-child {
  margin-bottom: 0
}
.company-contacts .__inner {
  margin-bottom: -30px
}
.company-contacts .__item {
  margin-bottom: 30px;
  line-height: 1.3;
  font-weight: 600;
  color: #25272a
}
.company-contacts .__ico {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  line-height: 1;
  font-size: 3.7rem;
  color: #f95a3f
}
.company-contacts .__ico:before {
  vertical-align: top
}
.company-contacts .__title {
  margin-bottom: 10px
}
.company-contacts .__title + .s-btns {
  padding-top: 5px
}
.company-contacts a:not([class]) {
  text-decoration: underline;
  color: inherit
}
.company-contacts a:not([class]):focus,
.company-contacts a:not([class]):hover {
  text-decoration: none
}
.content-container .demo-selection {
  background-color: #f95a3f;
  color: #fff
}
.content-container ol:not([class]),
.content-container ul:not([class]) {
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 20px
}
.content-container ol:not([class]):first-child,
.content-container ul:not([class]):first-child {
  margin-top: 0!important
}
.content-container ol:not([class]):last-child,
.content-container ul:not([class]):last-child {
  margin-bottom: 0!important
}
.content-container ol:not([class]) li,
.content-container ul:not([class]) li {
  margin-top: 10px;
  padding-left: 20px
}
.content-container ol:not([class]) li:before,
.content-container ul:not([class]) li:before {
  float: left;
  margin-left: -20px;
  margin-right: 5px
}
.content-container ol:not([class]) li:first-child,
.content-container ul:not([class]) li:first-child {
  margin-top: 0
}
.content-container ol:not([class]) {
  counter-reset: num
}
.content-container ol:not([class]) li:before {
  counter-increment: num;
  content: counter(num) ".";
  font-weight: 700;
  color: #f95a3f
}
.content-container ul:not([class]) li:before {
  content: "";
  width: 0;
  height: 0;
  margin-top: 7px;
  border: 3px solid #f95a3f;
  border-radius: 50%
}
.content-container hr {
  margin-top: 50px;
  margin-bottom: 50px;
  border: none;
  border-top: 3px solid #ebebeb
}
.content-container hr:first-child {
  margin-top: 0!important
}
.content-container hr:last-child {
  margin-bottom: 0!important
}
.content-container p b,
.content-container p strong {
  color: #333
}
.content-container .blockquot {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 20px;
  border-left: 4px solid #f95a3f;
  line-height: 1.4;
  font-size: 2rem
}
.content-container .blockquot:first-child {
  margin-top: 0
}
.content-container .blockquot:last-child {
  margin-bottom: 0
}
@media (min-width:576px) {
  .content-container .blockquot {
    font-size: 2.2rem
  }
}
@media (min-width:768px) {
  .content-container .blockquot {
    padding-left: 30px;
    font-size: 2.4rem
  }
}
.content-container .dropcaps .first-letter {
  float: left;
  margin-right: 10px;
  line-height: .9;
  font-size: 4.6rem;
  font-weight: 800;
  color: #f95a3f
}
.faq .__inner {
  margin-bottom: -35px
}
.faq .__item {
  position: relative;
  margin-bottom: 35px
}
.faq .__title {
  margin: 0
}
.faq p {
  margin-top: 5px;
  margin-bottom: 5px
}
.faq--numbered {
  counter-reset: faq-num
}
.faq--numbered .__title:before {
  display: inline;
  counter-increment: faq-num;
  content: counter(faq-num,decimal-leading-zero) ". "
}
.feature .__inner {
  margin-bottom: -60px
}
.feature .__item {
  position: relative;
  width: 100%;
  margin-bottom: 60px;
  text-align: inherit
}
.feature .__item .__ico {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
  line-height: 1
}
.feature .__item .__ico > svg {
  vertical-align: middle
}
.feature .__item .__title {
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: none
}
.feature .__item p {
  margin-top: 15px;
  margin-bottom: 15px
}
.footer {
  position: relative;
  background-color: #25272a;
  font-size: 1.6rem;
  font-weight: 600;
  color: #d3d3d3
}
.footer__line--first {
  padding-top: 50px;
  padding-bottom: 50px
}
.footer__item {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
  z-index: 2
}
.footer__navigation {
  line-height: 1.4
}
.footer__navigation:first-child {
  margin-top: -5px
}
.footer__navigation li {
  position: relative;
  margin-top: 5px
}
.footer__navigation li.active a {
  color: #fff
}
.footer__navigation li.active a:after {
  width: 30px
}
.footer__navigation a {
  color: inherit;
  text-decoration: none
}
.footer__navigation a:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  border-top: 2px solid #f95a3f;
  transition: width .3s ease-in-out
}
.footer__navigation a:focus,
.footer__navigation a:hover {
  color: #fff
}
.footer__navigation a:focus:after,
.footer__navigation a:hover:after {
  width: 30px
}
.footer__address {
  line-height: 1.4;
  font-style: normal
}
.footer__address a {
  color: inherit
}
.footer__form-subscribe .textfield {
  border-bottom-color: #737373;
  color: #fff
}
.footer__form-subscribe .textfield::-webkit-input-placeholder {
  color: #fff
}
.footer__form-subscribe .textfield::-moz-placeholder {
  color: #fff
}
.footer__form-subscribe .textfield:-moz-placeholder {
  color: #fff
}
.footer__form-subscribe .textfield:-ms-input-placeholder {
  color: #fff
}
.footer__form-subscribe .textfield.focus:not([disabled]),
.footer__form-subscribe .textfield:focus:not([disabled]) {
  border-color: #fff
}
.footer__form-subscribe i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 1em;
  font-size: 2rem;
  color: #555
}
.footer__form-subscribe i + .textfield {
  padding-right: 30px
}
.footer .__copy {
  font-size: 1.4rem;
  color: #83888b
}
.footer--s1 .footer__line--second {
  padding-top: 19px;
  border-top: 1px solid #4c4d4e
}
@media (min-width:576px) {
  .footer__item {
    margin-top: 20px;
    margin-bottom: 20px
  }
}
@media (min-width:768px) {
  .footer__line--first {
    padding-top: 75px
  }
  .footer--s2 .footer__line--first {
    padding-bottom: 75px
  }
}
.gallery {
  margin-bottom: 70px
}
.gallery:last-child {
  margin-bottom: 0
}
.gallery-filter {
  margin-left: -30px;
  margin-bottom: 40px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.gallery-filter li {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  margin-bottom: 20px;
  letter-spacing: 0
}
.gallery-filter a {
  display: block;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  line-height: 1.2;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  color: #b2b2b2
}
.gallery-filter a.selected,
.gallery-filter a:hover {
  color: #25272a
}
.gallery-filter a.selected {
  border-color: #f95a3f
}
.gallery--grid .__image {
  min-height: 200px;
  padding-top: 85.71429%
}
.gallery--slider {
  overflow: hidden
}
.gallery--slider .slick-slider {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 10px
}
.gallery--slider .slick-slide {
  padding-left: 5px;
  padding-right: 5px
}
.gallery--slider .__image {
  min-height: 300px
}
.gallery .__item {
  position: relative;
  vertical-align: top;
  width: 100%
}
@media (min-width:576px) {
  .gallery--slider .slick-slider {
    margin-right: -10%
  }
  .gallery--slider .__image {
    min-height: 360px
  }
  .gallery .__item[data-x="2"][data-y="1"] .__image {
    padding-top: 42.85714%
  }
  .gallery .__item[data-x="1"][data-y="2"] .__image {
    padding-top: 171.42857%
  }
}
.gallery .__item:hover .__image:after {
  opacity: .7
}
.gallery .__item:hover .__content {
  background-color: #f95a3f
}
.gallery .__item:hover .__link {
  opacity: 1;
  transform: scale(1) translateZ(0);
  transition-delay: .1s
}
.gallery .__image {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  overflow: hidden
}
.gallery .__image img,
.gallery .__image:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
.gallery .__image:after {
  content: "";
  background-color: #2c2c2c;
  opacity: 0;
  transition: opacity .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .brands-list .__image,
  .footer__navigation a:after,
  .gallery .__image:after,
  .widget--colors .color-label span:before {
    transition: none
  }
}
.gallery .__image img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%"
}
.gallery .__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 15px;
  background-color: transparent;
  color: #fff;
  transition: background-color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .gallery .__content {
    transition: none
  }
}
@media (min-width:576px) {
  .gallery .__content {
    padding: 25px 30px
  }
}
.gallery .__link,
.gallery .__title {
  color: inherit
}
.gallery .__link {
  display: block;
  width: 1em;
  line-height: 1;
  font-size: 2.3rem;
  opacity: 0;
  transform: scale(.4) translateZ(0);
  transition: opacity .3s ease-in-out,transform .3s ease-in-out
}
.goods .__inner {
  margin-bottom: -45px
}
.goods .__item {
  position: relative;
  width: 100%;
  margin-bottom: 45px
}
.goods .__item:hover .__btn {
  transform: translateY(0)
}
.goods .__image {
  position: relative;
  width: 100%;
  max-height: 400px;
  margin: auto;
  overflow: hidden
}
.goods .__image:before {
  content: "";
  display: block;
  padding-top: 157.40741%
}
.goods .__image--shadow {
  box-shadow: 0 0 49px 0 rgba(239,239,239,.31)
}
.goods .__image--bg-white {
  background-color: #fff
}
.goods .__image--bg-gray {
  background-color: #fbfbfb
}
.goods .__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-top: 45px solid transparent;
  border-bottom: 65px solid transparent
}
@media (min-width:768px) {
  .goods .__image img {
    border-top-width: 65px
  }
}
.goods .__image .__btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition: transform .3s ease-in-out
}
.goods .__content {
  padding-top: 25px
}
.goods .product-price {
  margin-left: -20px
}
.goods .product-price__item {
  margin-left: 20px;
  font-size: 2rem
}
.goods .__title {
  margin-top: 10px
}
.goods--slider .slick-list {
  margin-left: -15px;
  margin-right: -15px
}
.goods--slider .slick-slide {
  padding-left: 15px;
  padding-right: 15px
}
.goods--slider .slick-slider > .slick-dots {
  margin-top: 30px;
  text-align: center
}
.goods--slider .__item {
  margin-bottom: 0;
  vertical-align: top
}
.goods-catalog .goods-filter-btn-open {
  display: block;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 700;
  color: #25272a;
  cursor: pointer
}
.goods-catalog .goods-filter-btn-open i {
  display: inline-block;
  margin-right: 10px;
  line-height: 1.6rem;
  color: #b9b9b9
}
.goods-catalog .goods-filter-btn-open i:before {
  vertical-align: top
}
.goods-catalog .goods-filter-btn-close {
  display: block;
  position: absolute;
  top: 75px;
  right: 10px;
  width: 1em;
  line-height: 1;
  color: #323232;
  text-align: center;
  z-index: 5
}
.goods-catalog .ordering {
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 700;
  color: #25272a
}
.goods-catalog .ordering .nice-select {
  border: none;
  border-radius: 0;
  height: auto;
  margin-left: 5px;
  padding-left: 5px;
  line-height: inherit;
  font-size: inherit;
  font-weight: 600;
  color: #777
}
.goods-catalog .ordering .nice-select:after {
  width: 6px!important;
  height: 6px!important;
  margin-top: -4px!important
}
.goods-catalog .ordering .nice-select .list {
  left: auto;
  right: 0;
  min-width: 110px;
  margin-top: 10px;
  padding: 20px 25px;
  box-shadow: 0 0 49px 0 rgba(239,239,239,.31)
}
.goods-catalog .ordering .nice-select .option {
  position: relative;
  min-height: 0;
  margin-top: 15px;
  padding: 0;
  line-height: 1.2;
  font-weight: inherit
}
.goods-catalog .ordering .nice-select .option:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  border-top: 2px solid currentColor;
  pointer-events: none;
  transition: width .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .gallery .__link,
  .goods .__image .__btn,
  .goods-catalog .ordering .nice-select .option:before {
    transition: none
  }
}
.goods-catalog .ordering .nice-select .option:first-child {
  margin-top: 0
}
.goods-catalog .ordering .nice-select .option.focus,
.goods-catalog .ordering .nice-select .option.selected.focus,
.goods-catalog .ordering .nice-select .option:hover {
  background-color: transparent!important;
  color: #f95a3f
}
.goods-catalog .ordering .nice-select .option.selected:before {
  width: 20px
}
@media (max-width:767.98px) {
  .goods-catalog .goods-filter {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 320px;
    background-color: #fff;
    backface-visibility: hidden;
    transform: translateX(-100%);
    transition: transform .4s ease-in-out
  }
  .goods-catalog .goods-filter.is-active {
    box-shadow: 0 0 35px 0 rgba(0,0,0,.05);
    transform: translateX(0)
  }
  .goods-catalog .goods-filter__inner {
    width: 100%;
    height: 100%;
    padding: 0 30px 50px;
    border-top: 120px solid transparent;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: scrollbar
  }
}
@media screen and (max-width:767.98px) and (prefers-reduced-motion:reduce) {
  .goods-catalog .goods-filter {
    transition: none
  }
}
@media (max-width:575.98px) {
  .goods-catalog .goods-filter__inner {
    border-top-width: 100px
  }
}
@media (min-width:576px) {
  .goods-catalog .goods-filter-btn-close {
    top: 95px
  }
  .instafeed {
    margin-right: 30px
  }
}
@media (min-width:768px) {
  .goods-catalog:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 24%;
    background-color: #fff;
    z-index: -1
  }
  .goods-catalog .__bg {
    position: absolute;
    top: -100px;
    bottom: -100px;
    left: 0;
    right: 15px;
    background-color: #fff;
    z-index: -1
  }
  .goods-catalog .__bg + .goods-filter {
    margin-right: 10px
  }
  .goods-catalog .goods-filter-btn-close,
  .goods-catalog .goods-filter-btn-open {
    display: none
  }
}
@media (min-width:992px) {
  .goods-catalog .__bg {
    top: -140px;
    bottom: -140px
  }
}
@media (min-width:1800px) {
  .goods-catalog:before {
    width: 32%
  }
}
.goods-rent .__inner {
  margin-bottom: -45px
}
.goods-rent .__item {
  position: relative;
  width: 100%;
  margin-bottom: 45px;
  background-color: #fff
}
.goods-rent .__image {
  position: relative;
  width: 100%;
  min-height: 270px;
  max-height: 380px;
  margin: auto;
  overflow: hidden
}
.goods-rent .__image:before {
  content: "";
  display: block;
  padding-top: 137.03704%
}
.goods-rent .__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  border-top: 30px solid transparent
}
.goods-rent .__content {
  padding: 20px 15px 55px;
  text-align: center
}
.goods-rent .__price {
  margin-left: -20px;
  margin-bottom: 30px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.goods-rent .__price:last-child {
  margin-bottom: 0
}
.goods-rent .__price span {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  line-height: 1;
  font-size: 3rem;
  font-weight: 700
}
.goods-rent .__price span sub {
  bottom: auto;
  font-size: 66.66667%
}
.goods-rent .__price .__new {
  color: #f95a3f
}
.goods-rent .__price .__old {
  color: #c5c5c5
}
.goods-rent--slider .slider-special-layout__inner {
  box-shadow: 0 20px 30px 0 rgba(0,0,0,.05)
}
.goods-rent--slider .slick-track {
  background-color: #fff
}
.goods-rent--slider .__item {
  vertical-align: top;
  margin-bottom: 0
}
.goods-rent--slider .__item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 1px solid #eff2f5
}
.ingredients .__inner {
  margin-bottom: -30px
}
.ingredients .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  padding: 30px 0;
  background-color: #fbfbfb
}
.ingredients .__image {
  position: relative;
  flex: 0 0 40%;
  width: 40%;
  margin: auto;
  overflow: hidden
}
.ingredients .__image:before {
  content: "";
  display: block;
  padding-bottom: 95%
}
.ingredients .__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 90%;
  max-height: 90%;
  margin: auto
}
.ingredients .__content {
  flex: 1 0 60%;
  padding: 0 15px 30px 0
}
.ingredients .__title {
  margin-bottom: 5px
}
.ingredients .product-price__item {
  font-size: 2rem
}
.ingredients .__btn {
  position: absolute;
  bottom: 10px;
  right: 10px
}
.ingredients .__add-to-cart {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-height: 40px;
  padding: 10px;
  line-height: 1.3;
  font-size: 1.6rem;
  font-family: Assistant,sans-serif;
  font-weight: 600;
  text-align: center!important;
  text-decoration: none!important;
  text-shadow: none!important;
  letter-spacing: 0;
  color: #f95a3f;
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  z-index: 0;
  transition: background-color .25s ease-in-out,border-color .25s ease-in-out,color .25s ease-in-out
}
.ingredients .__add-to-cart i {
  display: inline-block;
  vertical-align: top;
  margin: -5px 10px -5px 0;
  line-height: 1;
  font-size: 2.5rem
}
.ingredients .__add-to-cart:focus,
.ingredients .__add-to-cart:hover {
  background-color: #f95a3f;
  color: #fff
}
.ingredients .product-label {
  left: 0;
  right: auto
}
.section:last-child .instafeed {
  margin-bottom: 30px
}
.instafeed .__item {
  position: relative;
  vertical-align: top;
  width: 100%
}
.instafeed .__item:hover .__image > a {
  background-color: rgba(249,90,63,.5)
}
.instafeed .__image {
  position: relative;
  width: 100%;
  height: 0;
  min-height: 150px;
  margin: auto;
  padding-top: 78.83436%;
  overflow: hidden
}
.instafeed .__image a,
.instafeed .__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%"
}
.instafeed .__image a {
  padding: 15px;
  line-height: 1;
  font-size: 2rem;
  color: #fff;
  transition: background-color .3s ease-in-out,color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .ingredients .__add-to-cart,
  .instafeed .__image a {
    transition: none
  }
}
.page-home .section--blog .decor-el {
  top: 15%;
  right: 0
}
.page-home-2 .section + .section--no-pt .icon-list:first-child {
  margin-top: -70px
}
@media (min-width:768px) {
  .goods-rent .__image img {
    border-top-width: 50px
  }
  .page-home-2 .section + .section--no-pt .icon-list:first-child {
    margin-top: -100px
  }
}
@media (min-width:992px) {
  .page-home-2 .section + .section--no-pt .icon-list:first-child {
    margin-top: -140px
  }
  .page-home-2 .section--features:before {
    content: "";
    display: block;
    position: absolute;
    top: 55%;
    bottom: 0;
    left: 0;
    width: 30px;
    background-color: #f95a3f
  }
  .pricing-table--s2 .col:first-child .__item:before,
  .pricing-table--s2 [class*=col-]:first-child .__item:before {
    content: none
  }
}
.page-home-2 .section--features .decor-el {
  top: 15%;
  width: 100%;
  text-align: center
}
@media (min-width:768px) {
  .page-posts-list .section:nth-of-type(1):before {
    content: "";
    position: absolute;
    top: 0;
    right: 42.5%;
    left: 0;
    bottom: 20%;
    background-color: #f7f7f7
  }
  .pricing-table--s2 {
    box-shadow: 0 20px 30px 0 rgba(0,0,0,.05)
  }
}
.page-404 .container:last-child {
  margin: auto
}
.page-404 .site-logo {
  margin-bottom: 30px
}
.page-404 p {
  margin-top: 40px;
  margin-bottom: 40px
}
.page-404 p:first-child {
  margin-top: 0
}
.page-404 p:last-child {
  margin-bottom: 0
}
@media (min-width:576px) {
  .instafeed .__image a {
    padding: 30px
  }
  .page-home-1 .section--about {
    background-image: url(../img/shape_bg.png);
    background-position: 150px 180px;
    background-repeat: no-repeat
  }
  .page-home-2 .section--about {
    background-image: url(../img/shape_bg.png);
    background-position: 150px 200px;
    background-repeat: no-repeat
  }
  .page-about main,
  .page-contact main,
  .page-elements-accordions main,
  .page-elements-alerts main,
  .page-elements-counters main,
  .page-elements-form main,
  .page-elements-icons main,
  .page-elements-media main,
  .page-elements-pricing main,
  .page-elements-tables main,
  .page-elements-tabs main,
  .page-faq main,
  .page-products main,
  .page-services main {
    background-image: url(../img/shape_bg.png);
    background-repeat: no-repeat
  }
  .page-about main,
  .page-services main {
    background-position: -150px 80px
  }
  .page-contact main,
  .page-elements-form main,
  .page-elements-tables main,
  .page-faq main {
    background-position: -50px -50px
  }
  .page-products main {
    background-position: right -70px top -45px
  }
  .page-elements-pricing main {
    background-position: right 70px top -60px
  }
  .page-elements-accordions main,
  .page-elements-alerts main,
  .page-elements-counters main,
  .page-elements-icons main,
  .page-elements-media main,
  .page-elements-tabs main {
    background-position: -195px -60px
  }
  .page-404 .site-logo {
    margin-top: 30px
  }
  .posts .__item--preview {
    display: flex;
    flex-direction: column;
    align-items: center
  }
  .posts .__item--preview .__header {
    flex: 0 0 auto
  }
  .posts .__item--preview .__body {
    flex: 1 0 auto
  }
  .posts .__item--preview .__footer {
    flex: 0 0 auto
  }
}
.posts .__inner {
  margin-bottom: -30px
}
.posts .__item {
  position: relative;
  width: 100%
}
.posts .__item .__content {
  position: relative;
  line-height: 1.6;
  font-size: 1.6rem
}
.posts .__item .__category {
  margin-bottom: 3px;
  color: #f95a3f
}
.posts .__item .__title {
  margin-top: 3px;
  text-transform: none
}
.posts .__item .__title:not(:last-child) {
  margin-bottom: 10px
}
.posts .__item--preview {
  margin-bottom: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: box-shadow .3s ease-in-out
}
.posts .__item--preview > div {
  width: 100%
}
.posts .__item--preview .__body .__content {
  padding-top: 30px
}
.posts .__item--preview .__footer .__content {
  padding-top: 10px
}
.posts .__item--preview .__image {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden
}
.posts .__item--preview .__image:before {
  content: "";
  display: block;
  padding-top: 77.77778%
}
.posts .__item--preview .__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
@media screen and (prefers-reduced-motion:reduce) {
  .posts .__item--preview,
  .posts .__item--preview .__image img {
    transition: none
  }
}
.posts .__item--preview .__content {
  padding-left: 15px;
  padding-right: 15px
}
@media (min-width:576px) {
  .posts .__item--preview .__content {
    padding-left: 25px;
    padding-right: 25px
  }
}
.posts .__item--preview p {
  margin-top: 10px;
  margin-bottom: 10px
}
.posts .__item--preview p:first-child {
  margin-top: 0
}
.posts .__item--preview p:last-child {
  margin-bottom: 0
}
.posts .__item--preview:hover .__image img {
  transform: scale(1.2) translateZ(0)
}
.posts .__item--shadow {
  box-shadow: 0 0 29px 0 rgba(174,175,175,.17)
}
.posts .__item--shadow:hover {
  box-shadow: 0 0 29px 0 rgba(174,175,175,.44)
}
.post-meta {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.post-meta__item {
  display: inline-block;
  margin-left: 20px;
  line-height: 1.2;
  font-size: 1.4rem;
  letter-spacing: 0;
  color: #b1b1b1
}
.post-meta__item:first-child {
  margin-left: 0
}
.post-meta__item a {
  color: inherit
}
.post-meta .__comment-post i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  line-height: 1;
  font-size: 1.2rem
}
.post-author__img {
  width: 50px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 50%
}
.post-author__name {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  color: #333
}
.pricing-table .__inner {
  margin-bottom: -50px
}
.pricing-table .__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-bottom: 50px;
  padding: 60px 15px;
  text-align: center;
  overflow: hidden
}
.pricing-table .__item--bordered {
  padding: 45px 15px;
  border: 15px solid #f1f1f1
}
.pricing-table .__item .__header {
  flex: 0 0 auto
}
.pricing-table .__item .__body {
  flex: 1 0 auto
}
.pricing-table .__item .__footer {
  flex: 0 0 auto
}
.pricing-table .__label {
  position: absolute;
  line-height: 1;
  font-size: 1.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.pricing-table .__label + .__title {
  margin-top: 0
}
.pricing-table .__price {
  margin-top: 25px;
  margin-bottom: 25px;
  line-height: 1;
  font-size: 5rem;
  font-weight: 700;
  letter-spacing: -3px;
  color: #f95a3f
}
.pricing-table .__price:first-child {
  margin-top: 0
}
.pricing-table .__price:last-child {
  margin-bottom: 0
}
.pricing-table .__desc-list {
  line-height: 1.2;
  text-align: left
}
.pricing-table .__desc-list li {
  margin-top: 20px;
  padding-left: 30px
}
.pricing-table .__desc-list li:first-child {
  margin-top: 0
}
.pricing-table .__desc-list li > i:first-child {
  float: left;
  margin-top: 5px;
  margin-left: -30px;
  width: 1em;
  line-height: 1;
  font-size: 1.3rem
}
.pricing-table .__desc-list .fontello-ok {
  color: #45dbab
}
.pricing-table .disabled {
  color: #c8c8c8
}
.pricing-table--s1 .__item--shadow {
  box-shadow: 0 20px 30px 0 rgba(0,0,0,.05)
}
.pricing-table--s1 .__item--active {
  background-color: #f95a3f;
  border-color: #f95a3f;
  color: #fff
}
.pricing-table--s1 .__item--active .__desc-list .fontello-ok,
.pricing-table--s1 .__item--active .__price,
.pricing-table--s1 .__item--active .__title,
.pricing-table--s1 .__item--active .__value {
  color: inherit
}
.pricing-table--s1 .__item--active .__label {
  top: 4px;
  right: 4px;
  background-color: #fff;
  padding: 7px 20px;
  color: #01a1d2
}
.pricing-table--s1 .__item--active .disabled {
  color: #fbb9ad
}
.pricing-table--s1 .__item--active .custom-btn--s1 {
  border-color: #fff;
  background-color: transparent;
  color: #fff
}
.pricing-table--s1 .__item--active .custom-btn--s1:focus,
.pricing-table--s1 .__item--active .custom-btn--s1:hover {
  background-color: #fff;
  color: #333
}
.pricing-table--s2 .__inner {
  margin-bottom: 0
}
@media (min-width:768px) and (max-width:991.98px) {
  .pricing-table--s2 .col:nth-of-type(odd) .__item:before,
  .pricing-table--s2 [class*=col-]:nth-of-type(odd) .__item:before {
    content: none
  }
}
.pricing-table--s2 .__item {
  margin-bottom: 0
}
@media (min-width:768px) {
  .pricing-table--s2 .__item:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px solid #eff2f5
  }
}
.pricing-table--s2 .__item .__label {
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #f95a3f transparent transparent;
  color: #fff
}
.pricing-table--s2 .__item .__label i {
  position: absolute;
  top: 10px;
  right: -40px
}
.pricing-table--s3 {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}
.pricing-table--s3 table {
  min-width: 650px
}
.pricing-table--s3 tr:first-child td {
  border-top: none;
  padding-top: 0
}
.pricing-table--s3 tr:last-child td {
  padding-bottom: 0
}
.pricing-table--s3 td {
  padding-top: 24px;
  padding-bottom: 25px;
  padding-left: 20px;
  border-top: 1px solid #eff2f5
}
.pricing-table--s3 td:first-child {
  vertical-align: top;
  padding-left: 0
}
.pricing-table--s3 .__price {
  font-size: 4rem;
  white-space: nowrap
}
.product-single .__gallery {
  position: relative;
  background-color: #fbfbfb
}
.product-single .__gallery .__img {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  padding-top: 141.48936%;
  overflow: hidden
}
.product-single .__gallery .__img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto
}
.product-single .__gallery .slick-dots {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  text-align: center
}
.product-single .__name {
  margin-bottom: 5px
}
.product-single .__name:first-child {
  margin-top: -.25em
}
.product-single .__categories {
  line-height: 1.5;
  font-size: 1.4rem;
  font-weight: 700;
  color: #c8c8c8;
  text-transform: uppercase
}
.product-single .__categories span {
  color: #f95a3f
}
.product-single .__review {
  margin-top: 25px;
  line-height: 1;
  font-size: 1.6rem
}
.product-single .product-price {
  margin-top: 35px;
  margin-left: -20px;
  margin-bottom: 35px
}
.product-single .product-price__item {
  margin-left: 20px;
  font-size: 3rem
}
.product-single .product-price__item--old {
  text-decoration: line-through
}
.product-single .__add-to-cart {
  max-width: 350px
}
.product-single .__add-to-cart button[type=submit] {
  margin-top: 0;
  margin-left: -2px;
  white-space: nowrap
}
.product-single .__add-to-cart button[type=submit] i {
  display: inline-block;
  vertical-align: top;
  margin: -6px 15px -6px 0;
  line-height: 1;
  font-size: 2.7rem;
  font-weight: 400
}
.product-single .quantity-counter input {
  height: 60px;
  padding-top: 17px;
  padding-bottom: 17px
}
.product-single .__add-review.sticky-top {
  top: 120px
}
.product-single .__add-review .rating {
  margin-top: 30px;
  margin-bottom: 20px;
  direction: rtl;
  text-align: left
}
.product-single .__add-review .rating .__note {
  float: left;
  margin-right: 10px;
  font-size: 1.6rem;
  line-height: 1;
  letter-spacing: 0;
  direction: ltr
}
.products-cards .row {
  margin-left: -5px;
  margin-right: -5px
}
.products-cards .row > .col,
.products-cards .row > [class*=col-] {
  padding-left: 5px;
  padding-right: 5px
}
.products-cards .__inner {
  margin-bottom: -10px
}
.products-cards .__item {
  position: relative;
  width: 100%;
  margin-bottom: 10px
}
.products-cards .__item:hover .__image {
  box-shadow: 0 5px 29px 0 rgba(0,0,0,.31)
}
.products-cards .__image {
  position: relative;
  width: 100%;
  height: 200px;
  margin: auto;
  overflow: hidden;
  transition: box-shadow .3s ease-in-out
}
@media (min-width:576px) {
  .products-cards .__image {
    height: 250px
  }
}
.products-cards .__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%"
}
.products-cards .__content {
  position: absolute;
  left: 20px;
  bottom: 20px
}
@media (min-width:768px) {
  .products-cards .__content {
    left: 30px;
    bottom: 30px
  }
}
@media (min-width:992px) {
  .products-cards .__content {
    left: 40px
  }
  .promo-banner--s1 {
    background-image: url(../img/promo_banner_1_bg.jpg);
    background-repeat: no-repeat;
    background-position: center right
  }
}
.products-cards .__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
.promo-banners .row {
  margin-left: -5px;
  margin-right: -5px
}
.promo-banners .row > .col,
.promo-banners .row > [class*=col-] {
  padding-left: 5px;
  padding-right: 5px
}
.promo-banners .__item {
  display: block;
  margin: 10px 0
}
.promo-banner__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 230px;
  padding: 40px 0
}
.promo-banner .__title {
  color: #fff
}
.promo-banner .__title:first-child {
  margin-top: -.2em
}
.promo-banner--s1 {
  background-color: #f95a3f;
  color: #ffb9ad
}
.promo-banner--s1 .__title {
  margin-bottom: 10px
}
.promo-banner--s1 .__title:first-child {
  margin-top: -.15em
}
.promo-banner--s1 p {
  margin-top: 10px;
  margin-bottom: 10px
}
@media only screen and (min-width:1500px) {
  .promo-banner--s1.promo-banner--with-offset {
    margin-left: 140px
  }
  .promo-banner--s1.promo-banner--with-offset .promo-banner__inner {
    margin-left: -140px
  }
}
.promo-banner--s2 {
  background: url(../img/promo_banner_2_bg.jpg) center left 5%/cover no-repeat #214d88
}
.testimonial-ico {
  display: block;
  width: 100px;
  height: 80px;
  margin-right: 30px;
  padding-left: 62px;
  background-color: #020203;
  line-height: .85;
  font-size: 15rem;
  font-weight: 700;
  font-style: normal;
  color: #f95a3f;
  user-select: none
}
.review {
  position: relative
}
.review__item {
  position: relative;
  width: 100%;
  line-height: 1.4;
  font-size: 2rem
}
.review__item__author-name {
  position: relative;
  padding-top: 15px;
  padding-left: 75px;
  line-height: 1
}
.review__item__author-name:before {
  content: "";
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 55px;
  height: 0;
  border-top: 1px solid #f95a3f
}
.review--slider .review__item {
  vertical-align: middle
}
.services .__inner {
  margin-bottom: -45px
}
.services .__item {
  position: relative;
  width: 100%;
  margin-bottom: 45px
}
.services .__item:hover .__content:before {
  transform: translateY(0)
}
.services .__item:hover .__content:after {
  color: #fff
}
.services .__image {
  position: relative;
  width: 100%;
  margin: auto;
  max-height: 350px;
  overflow: hidden
}
@media (min-width:576px) {
  .review__item {
    font-size: 2.4rem
  }
  .services .__image {
    max-height: 420px
  }
}
.services .__image:before {
  content: "";
  display: block;
  padding-top: 148.27586%
}
.services .__image:after {
  content: "";
  background-color: rgba(4,4,4,.34)
}
.services .__image img,
.services .__image:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
.services .__image img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%"
}
.services .__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 25px 15px 18px;
  border-bottom: 7px solid #f95a3f;
  color: #fff
}
@media (min-width:768px) {
  .services .__content {
    padding-left: 25px;
    padding-right: 25px
  }
}
.services .__content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f95a3f;
  transform: translateY(100%);
  transition: transform .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .products-cards .__image,
  .services .__content:before {
    transition: none
  }
}
.services .__content:after {
  content: attr(data-num);
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  line-height: 1;
  font-size: 4rem;
  font-weight: 700;
  color: #f95a3f;
  transform: translateY(-55%);
  transition: color .3s ease-in-out
}
.services .__title,
.services .__title a {
  position: relative;
  color: inherit
}
.services--slider .slick-list {
  margin-left: -5px;
  margin-right: -5px
}
.services--slider .slick-slide {
  padding-left: 5px;
  padding-right: 5px
}
.services--slider .__item {
  vertical-align: top;
  margin-bottom: 0
}
.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  background-color: #25272a;
  padding: 185px 30px 50px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #d3d3d3;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateX(-100%);
  z-index: 4;
  transition: transform .4s ease-in-out
}
.side-menu.is-active {
  transform: translateX(0)
}
.side-menu__inner {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar
}
.side-menu__menu {
  margin-top: 9.5vh;
  margin-bottom: 9.5vh;
  line-height: 1.4
}
.side-menu__menu li {
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase
}
.side-menu__menu li:first-child {
  margin-top: 0
}
.side-menu__menu li > a:focus,
.side-menu__menu li > a:hover,
.side-menu__menu li.active > a {
  color: #6e6e6e
}
.side-menu__menu li > a:focus:after,
.side-menu__menu li > a:hover:after,
.side-menu__menu li.active > a:after {
  width: 45px
}
.side-menu__menu a {
  position: relative;
  color: inherit;
  text-decoration: none
}
.side-menu__menu a:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  margin-top: 5px;
  border-top: 2px solid #f95a3f;
  transition: width .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .services .__content:after,
  .side-menu,
  .side-menu__menu a:after {
    transition: none
  }
}
.side-menu__contacts {
  margin-top: 9.5vh;
  margin-bottom: 9.5vh
}
.side-menu .s-btns {
  margin-top: 20px
}
.sidebar {
  position: relative;
  min-height: 100%;
  transform: translateZ(0);
  z-index: 3
}
.steps {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}
.steps .__inner {
  overflow: hidden
}
.steps .col:last-child .__line,
.steps [class*=col-]:last-child .__line {
  margin-right: 0
}
.steps .__item {
  position: relative;
  width: 100%;
  margin-bottom: 20px
}
.steps .__item .__num {
  line-height: 1.2;
  font-size: 1.4rem;
  font-weight: 700;
  color: #333
}
.steps .__item .__num span {
  display: inline-block;
  line-height: 1;
  font-size: 3rem;
  color: #f95a3f
}
.steps .__item .__num span:after {
  content: "/";
  font-size: 2rem
}
.steps .__item .__line {
  position: relative;
  margin-top: 25px;
  margin-right: -30px;
  margin-bottom: 20px;
  border-top: 1px solid #ededed
}
.steps .__item .__line .__point {
  position: absolute;
  top: -8px
}
.steps .__item .__line .__point--last {
  right: 0
}
.steps .__item .__point {
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 2px solid #f95a3f;
  border-radius: 50%;
  line-height: 1;
  color: #f95a3f
}
.steps .__item .__point--last {
  background-color: #f95a3f
}
.steps .__item .__point i {
  position: absolute;
  bottom: 100%;
  margin-bottom: 10px;
  font-size: 1.3rem
}
.team .__inner {
  margin-bottom: -50px
}
.team .__item {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  text-align: center
}
@media (min-width:576px) {
  .team .__item {
    padding: 0 20px
  }
}
.team .__image {
  position: relative;
  width: 100%;
  min-height: 200px;
  margin: auto;
  overflow: hidden
}
.team .__image:before {
  content: "";
  display: block;
  padding-top: 124.44444%
}
.team .__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%"
}
.team .__content {
  padding-top: 37px;
  line-height: 1.2
}
.team .__position {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #f95a3f
}
.team .__name {
  margin-top: 5px
}