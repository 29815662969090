/* --------------------------------
   cart
-------------------------------- */

.cart
{
	&__form {}

	&__table
	{
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		table { min-width: 650px; }

		thead
		{
			line-height: 1;
			font-size: 1.6rem;
			font-weight: 700;
			text-transform: uppercase;
			color: #25272a;

			tr { border-bottom: 2px solid #f1f1f1; }

			td { padding-bottom: 25px; }
		}

		tbody
		{
			tr { border-bottom: 1px solid #f1f1f1; }

			td
			{
				padding-top: 30px;
				padding-bottom: 29px;
			}
		}

		td
		{
			padding-left: 15px;

			&:first-child { padding-left: 0; }
		}

		.__image
		{
			position: relative;
			width: 120px;
			height: 120px;
			background-color: #fbfbfb;
			margin-right: 20px;

			@include media-breakpoint-up(lg)
			{
				margin-right: 40px;
			}

			a
			{
				display: block;
				width: 100%;
				height: 100%;
			}

			img
			{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				max-width: 90%;
				max-height: 80%;
			}
		}

		.__name
		{
			display: inline-block;
			vertical-align: top;
			line-height: 1.4;
			margin-bottom: 5px;
			font-size: 1.8rem;
			font-weight: 700;
			text-decoration: none;

			&:last-child { margin-bottom: 0; }

			&:not(:hover):not(:focus) { color: #333; }
		}

		.__price,
		.__total
		{
			font-weight: 700;
			white-space: nowrap;
		}

		.__price { color: #333; }

		.__total { color: $primary-color; }

		.__remove
		{
			display: inline-block;
			width: 20px;
			height: 20px;
			line-height: 20px;
			text-align: center;
		}

		.quantity-counter
		{
			width: 1px;
			min-width: 130px;

			@include media-breakpoint-up(lg)
			{
				min-width: 150px;
			}
		}
	}

	&__coupon
	{
		width: 100%;
		max-width: 440px;
	}

	&__total
	{
		line-height: 1.3;
		min-width: 230px;
		font-weight: 700;
		color: #333;

		tbody
		{
			tr { }

			td { padding-bottom: 10px; }
		}

		tfoot
		{
			tr { }

			td { padding-top: 20px; }
		}

		tr { }

		td { }
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}