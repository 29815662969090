/* --------------------------------
   goods
-------------------------------- */

.goods
{
	$itemMarginBottom: 45px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $itemMarginBottom;

		&:hover
		{
			.__btn { transform: translateY(0); }
		}
	}

	.__image
	{
		position: relative;
		width: 100%;
		max-height: 400px;
		margin: auto;
		overflow: hidden;

		&:before
		{
			content: "";
			display: block;
			padding-top: percentage(425/270);
		}

		&--shadow { box-shadow: 0px 0px 49px 0px rgba(#efefef, 0.31); }

		&--bg-white { background-color: $white; }
		&--bg-gray  { background-color: #fbfbfb; }

		img
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 100%;
			max-height: 100%;
			margin: auto;
			border-top: 45px solid transparent;
			border-bottom: 65px solid transparent;

			@include media-breakpoint-up(md)
			{
				border-top-width: 65px;
			}
		}

		.__btn
		{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			transform: translateY(100%);
			@include transition(transform 300ms ease-in-out);
		}
	}

	.__content { padding-top: 25px; }

	.product-price
	{
		margin-left: -20px;

		&__item
		{
			margin-left: 20px;
			font-size: 2rem;
		}
	}

	.__title { margin-top: 10px; }
}

.goods--slider
{
	$slideGutter: 30px;

	@if $slideGutter > 0
	{
		.slick-list
		{
			margin-left: -$slideGutter/2;
			margin-right: -$slideGutter/2;
		}

		.slick-slide
		{
			padding-left: $slideGutter/2;
			padding-right: $slideGutter/2;
		}
	}

	.slick-slider
	{
		> .slick-dots
		{
			margin-top: 30px;
			text-align: center;
		}
	}

	.__item
	{
		margin-bottom: 0;
		vertical-align: top;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}